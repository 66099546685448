import { useState } from 'react'
import { Table, Space, Button, Modal, Tree } from 'antd';

import { Link } from 'react-router-dom';

// Custom Components
import ButtonPrimary from '../../Shared/Button.Primary';
import FuzTypeCreateModal from './Modals/FuzType.Create.Modal'
import FuzTypeUpdateAttributeModal from "./Modals/FuzType.UpdateAttribute.Modal"
import FuzTypeTableExpand from './FuzType.Table.Expand'

// Services
import FuzAllocationService from '../../../domain/FuzAllocation/FuzAllocation.Service'

// Initiate Services
const fuzAllocationService = new FuzAllocationService();

interface PropsInterface {
    FuzTypes: any;
    FuzTypeCreateSurvey: any;
    createFuzType: any;
    deleteFuzType: any;
    TeamsTreeData: any;
    updateAllocation: any;
    updateAttributeOfFuzType:any;
}

export default function FuzType(props: PropsInterface) {
    const [modalCreateFuzTypeVisible, setModalCreateFuzTypeVisible] = useState(false)
    const [modalUpdateAllocationVisible, setModalUpdateAllocationVisible] = useState(false)
    const [modalDeleteFuzTypeVisible, setModalDeleteFuzTypeVisible] = useState(false)
    const [modalUpdateAttributeFuzType, setModalUpdateAttributeFuzType] = useState(false)

    const [selectedFuzType, setSelectedFuzType] = useState<string>()
    const [checkedTeams, setCheckedTeams] = useState<string[] | undefined>([])
    const [attributeToUpdate, setAttributeToUpdate] = useState<string>("")

    const columns = [
        {
            title: 'Fuz Type',
            dataIndex: 'TypeName',
            key: 'TypeName',
            render:  (text: any, record: any) =>
            <Link to={`/admin/fuztype/${record.TypeId}`}>
                {text}
            </Link>
        },
        // {
        //     title: 'Description',
        //     dataIndex: 'UploadDescription',
        //     key: 'UploadDescription'
        // },
        // {
        //     title: 'Max Upload Size (Mb)',
        //     dataIndex: 'MaxFileUploadSizeMb',
        //     key: 'MaxFileUploadSizeMb'
        // },
        {
            title: 'Action',
            key: 'action',
            render: (text: any, record: any) => (
                <Space size="middle">
                    <ButtonPrimary text="Change Allocation" onClick={() => onClickUpdateAllocation(record.TypeId)} />
                    <Button danger onClick={() => onClickDeleteFuzType(record.TypeId)} >Delete</Button>
                </Space>
            ),
        },
    ];

    // Create Fuz Type
    async function createFuzType(data: any) {
        await props.createFuzType(data)
        setModalCreateFuzTypeVisible(false)
    }

    // Delete Fuz Type
    async function onClickDeleteFuzType(FuzTypeId: string) {
        setSelectedFuzType(FuzTypeId)
        setModalDeleteFuzTypeVisible(true)
    }
    async function onDeleteFuzType() {
        await props.deleteFuzType(selectedFuzType)
        setSelectedFuzType("")
        setModalDeleteFuzTypeVisible(false)
    }

    // Update Allocation
    async function onClickUpdateAllocation(FuzTypeId: string) {
        setSelectedFuzType(FuzTypeId)
        console.log("Update Allocation click")
        const FuzTypeAllocations: string[] = await fuzAllocationService.getAllocationsForFuzType(FuzTypeId)
        console.log("FuzTypeAllocations: ",FuzTypeAllocations)
        setCheckedTeams(FuzTypeAllocations)
        setModalUpdateAllocationVisible(true)
    }

    // When Checking a TeamKey, the checked teams are stored in state
    function onCheckUpdateTeamTree(selectedKeys: any, info: any) {
        const checkedKeys = selectedKeys.checked;
        setCheckedTeams(checkedKeys)
        console.log("Selected Keys: ", checkedKeys); //TODO remove
    }
    async function handleModalSaveAllocation() {
        await props.updateAllocation(selectedFuzType, checkedTeams)
        setSelectedFuzType("")
        setCheckedTeams([])
        setModalUpdateAllocationVisible(false)
    }

    // Update Attribute
    async function handleClickUpdateAttribute(FuzTypeId:string,AttributeName:string) {
        console.log("Updating attributes: ",AttributeName,"for : ",FuzTypeId);
        setSelectedFuzType(FuzTypeId)
        setAttributeToUpdate(AttributeName)
        setModalUpdateAttributeFuzType(true)
    }

    async function updateAttributeOfFuzType(AttributeName:string,AttributeValue:string){
        await props.updateAttributeOfFuzType(selectedFuzType,AttributeName,AttributeValue)
        setSelectedFuzType("")
    }

    async function handleSwitchEnabled(FuzTypeId: string,Enabled:Boolean){
        console.log("Updating enabled: ",Enabled,"for : ",FuzTypeId);
        await props.updateAttributeOfFuzType(FuzTypeId,"Enabled",Enabled)
    }
    
    return (
        <div>
            {props.FuzTypes ?
                <div>
                    <Space direction="vertical">
                        <ButtonPrimary text="Create FuzType" onClick={() => setModalCreateFuzTypeVisible(true)} />
                    </Space>
                    <Table
                        columns={columns}
                        dataSource={props.FuzTypes}
                        rowKey="TypeId"
                        expandable={{
                            expandedRowRender: record => 
                            <FuzTypeTableExpand 
                                FuzType={record} 
                                handleClickUpdateAttribute={(FuzTypeId:string,AttributeName:string)=>handleClickUpdateAttribute(FuzTypeId,AttributeName)}
                                handleSwitchEnabled={(FuzTypeId:string,Enabled:boolean)=>handleSwitchEnabled(FuzTypeId,Enabled)}
                                />,
                        }}
                    />
                    <Modal
                        title="Delete Fuz Type"
                        visible={modalDeleteFuzTypeVisible}
                        onCancel={() => setModalDeleteFuzTypeVisible(false)}
                        onOk={() => onDeleteFuzType()}
                    >Are you sure you want to delete?</Modal>
                    <Modal
                        title="Update Allocation"
                        visible={modalUpdateAllocationVisible}
                        onCancel={() => setModalUpdateAllocationVisible(false)}
                        footer={[

                            <Space>
                                <Button key="Cancel" onClick={() => setModalUpdateAllocationVisible(false)}>
                                    Cancel
                                </Button>
                                <ButtonPrimary text="Save" onClick={() => handleModalSaveAllocation()} />
                            </Space>
                        ]}
                    >
                        <Tree
                            checkable
                            checkStrictly={true}
                            treeData={props.TeamsTreeData}
                            defaultExpandAll={true}
                            checkedKeys={checkedTeams}
                            onCheck={onCheckUpdateTeamTree}
                        />
                    </Modal>
                    <FuzTypeCreateModal modalVisible={modalCreateFuzTypeVisible} setModalVisible={(bool: any) => setModalCreateFuzTypeVisible(bool)} survey={props.FuzTypeCreateSurvey} createFuzType={(data: any) => createFuzType(data)} />
                    <FuzTypeUpdateAttributeModal 
                        modalVisible={modalUpdateAttributeFuzType} 
                        setModalVisible={(bool: any) => setModalUpdateAttributeFuzType(bool)} 
                        attributeName={attributeToUpdate}
                        updateAttributeFuzType={(AttributeName: string,AttributeValue:string) => updateAttributeOfFuzType(AttributeName,AttributeValue)}
                    />
                </div>
                : "y"}
        </div>
    )
}