import { Result } from 'antd';
import ButtonPrimary from '../Shared/Button.Primary'

interface PropsInterface{
    clearUploadState:any;
}

export default function ResultSuccess(props: PropsInterface) {
    return (
        <Result
            status="success"
            title="Upload gelukt!"
            // subTitle="Insert subtitle."
            extra={[
                <ButtonPrimary text="Nieuwe Upload Poging" onClick={()=> props.clearUploadState()} />
            ]}
        />
    )
}