import { useState, useEffect } from 'react'
import { PageHeader, Tabs,Space,Modal,Button} from 'antd';
import ButtonPrimary from '../../Shared/Button.Primary';
import ButtonRefresh from '../../Shared/Button.Refresh'
import styled from 'styled-components';
import { FuzTypeDTO } from '../../../domain/FuzType/FuzType.DTO';

import { FuzEventDistinctUsersDTO } from '../../../domain/FuzType/FuzEvent.DTO'

import { Survey } from '../../Shared/Survey/Survey'
import FuzEventTable from './FuzEventTable'
import FuzEventUsersTable from './FuzEventUsersTable'

import SurveyService from '../../../domain/Surveys/Survey.service'
import FuzTypeService from '../../../domain/FuzType/FuzType.Service'
import empty from 'antd/lib/empty';
const surveyService = new SurveyService();
const fuzTypeService = new FuzTypeService();

interface PropsInterface {
    FuzType: FuzTypeDTO;
    fuzEvents: any[];
    fuzEventsDistinctUsers?: FuzEventDistinctUsersDTO[];
    TeamsTreeData?: any;
}

const TabsContainer = styled.div`
  .ant-tabs-tab.ant-tabs-tab-active {
  border-bottom: 2px solid #1c7074;
  color: #1c7074;
  z-index: 2;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color:#1c7074;
}
.site-page-header-ghost-wrapper {
  padding: 24px;
  background-color: #f5f5f5;
}

`;




export default function FuzTypeInfo(props: PropsInterface) {
    const FuzType: FuzTypeDTO = props.FuzType

    const [fuzTypeReadOnly, setFuzTypeReadOnly] = useState<boolean>(true);
    const [fuzTypeCreateSurveyDefinition, setFuzTypeCreateSurveyDefinition] = useState();
    const [fuzTypeCreateSurveyData, setFuzTypeCreateSurveyData] = useState<Record<string, any>>();

    const [updateModalVisible, setUpdateModalVisible] = useState<boolean>(false);


    useEffect(() => {
        const getSurveyData = async () => {
            const data: any = await surveyService.getFuzTypeCreateSurvey()
            setFuzTypeCreateSurveyDefinition(data)

            const surveyData: Record<string, any> = {
                TypeName: FuzType.TypeName,
                AllowedFileTypes: FuzType.AllowedFileTypes,
                MaxFileUploadSizeMb: FuzType.MaxFileUploadSizeMb,
                UploadDescription: FuzType.UploadDescription,
                ValidationSchemas: FuzType.ValidationSchemas,
                Enabled: FuzType.Enabled,
                FileNameRegex: FuzType.FileNameRegex
            }
            setFuzTypeCreateSurveyData(surveyData)
        }
        getSurveyData()
    }, []);

    async function refresh() {
        const data: any = await surveyService.getFuzTypeCreateSurvey()
        setFuzTypeCreateSurveyDefinition(data)

        const surveyData: Record<string, any> = {
            TypeName: FuzType.TypeName,
            AllowedFileTypes: FuzType.AllowedFileTypes,
            MaxFileUploadSizeMb: FuzType.MaxFileUploadSizeMb,
            UploadDescription: FuzType.UploadDescription,
            ValidationSchemas: FuzType.ValidationSchemas,
            Enabled: FuzType.Enabled,
            FileNameRegex: FuzType.FileNameRegex
        }
        setFuzTypeCreateSurveyData(surveyData)
        window.location.reload();
    }

    async function onSaveEditSurvey(data: any) {
        console.log("onSaveEditSurvey", data);
        try {
            await fuzTypeService.updateFullFuzType(FuzType.TypeId, data);
            setFuzTypeCreateSurveyData(empty)
            await refresh();
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <div>
        <TabsContainer>
            <div className="site-page-header-ghost-wrapper">
                <PageHeader
                    ghost={false}
                    onBack={() => window.history.back()}
                    title={`${FuzType.TypeName}`}
                    subTitle={FuzType.TypeId}
                    extra={[
                        <Space>
                            {/* <ButtonPrimary text='Update Allocation' onClick={()=>setUpdateModalVisible(true)}/> */}
                            <ButtonRefresh text="" onClick={() => refresh()} />
                        </Space>

                        // <Button key="2">Operation</Button>,
                        // <Button key="1" type="primary">
                        //     Primary
                        // </Button>,
                    ]}
                >
                    {/* <Descriptions size="small" column={1}>
                        <Descriptions.Item label="UploadDescription">{FuzType.UploadDescription}</Descriptions.Item>
                        <Descriptions.Item label="S3Path">{FuzType.S3Path}</Descriptions.Item>
                    </Descriptions> */}

                    <Tabs defaultActiveKey="1">
                        <Tabs.TabPane tab="Info" key="1">
                            {fuzTypeReadOnly
                                ? <ButtonPrimary text="Edit Fuz Type" onClick={() => setFuzTypeReadOnly(false)} />
                                : <ButtonPrimary text="Cancel Edit" onClick={() => setFuzTypeReadOnly(true)} />
                            }
                            <Survey definition={fuzTypeCreateSurveyDefinition} data={fuzTypeCreateSurveyData} readonly={fuzTypeReadOnly} onComplete={(data: any) => onSaveEditSurvey(data.valuesHash)} />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Events" key="5">
                            <FuzEventTable events={props.fuzEvents} />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Users" key="3">
                            {props.fuzEventsDistinctUsers ?
                                <FuzEventUsersTable distinctUsers={props.fuzEventsDistinctUsers} />
                                : ""}
                            {/* <FuzEventTable events={props.fuzEvents} /> */}
                        </Tabs.TabPane>
                        {/* <Tabs.TabPane tab="Template" key="4">
                            <ButtonPrimary text="Download Template" />
                        </Tabs.TabPane> */}
                    </Tabs>
                </PageHeader>
            </div>
        </TabsContainer >
        <Modal
        title="Update Allocation"
        visible={updateModalVisible}
        onCancel={() => setUpdateModalVisible(false)}
        footer={[

            <Space>
                <Button key="Cancel" onClick={() => setUpdateModalVisible(false)}>
                    Cancel
                </Button>
                <ButtonPrimary text="Save" onClick={() => setUpdateModalVisible(false)} />
            </Space>
        ]}
    >
        <p>test</p></Modal>
        </div>
    )
}