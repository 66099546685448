// Components
import UploadContainer from '../components/Upload/Upload.container'
import UploadEmpty from '../components/Upload/Upload.Empty'

// Domain
import {UserDTO} from '../domain/User/User.DTO'
import { FuzTypeDTO } from '../domain/FuzType/FuzType.DTO'
import { FuzUploadDTO } from '../domain/FuzType/FuzUpload.DTO'

interface PropsInterface {
    userInfo: UserDTO;
    fuzTypes: FuzTypeDTO[];
    userUploads:FuzUploadDTO[];
}

export default function UploadRoute(props: PropsInterface) {
    return (
        <div>
            {props.fuzTypes && props.fuzTypes.length>0?
                <UploadContainer 
                    fuzTypes={props.fuzTypes} 
                    userInfo={props.userInfo} 
                    userUploads={props.userUploads}
                    />
                : <UploadEmpty />}
        </div>
    )
}