import { Table, Space } from 'antd';

import ButtonInfo from '../../Shared/Button.Info';
import {FuzEventDistinctUsersDTO} from '../../../domain/FuzType/FuzEvent.DTO'

interface Propsinterface {
    distinctUsers: FuzEventDistinctUsersDTO[];
}

export default function FuzEventUsersTable(props: Propsinterface) {
    
    const columns = [
        {
            title: 'Users',
            dataIndex: 'UserId',
            key: 'UserId',
        },
        {
            title: 'Info',
            key: 'action',
            render: (text:string,record:FuzEventDistinctUsersDTO) => (
                <Space size="middle">
                    <ButtonInfo text="Info" disabled={true} />
                </Space>
            ),
        },

    ];

    return (
        <div>
            <Table columns={columns} dataSource={props.distinctUsers} />
        </div>
    )
}