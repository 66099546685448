import { useState, useEffect } from 'react'
// Components
import { FuzTypeDTO, FuzTypeCreateDTO } from '../../../domain/FuzType/FuzType.DTO'
import FuzType from './FuzType.Table'
// Import Services
import SurveyService from '../../../domain/Surveys/Survey.service';
import FuzTypeService from '../../../domain/FuzType/FuzType.Service';
import NotificationsService from '../../Shared/Notifications'
import FuzAllocationService from '../../../domain/FuzAllocation/FuzAllocation.Service'
// Initiate Services
const surveyService = new SurveyService();
const fuzTypeService = new FuzTypeService();
const notificationsService = new NotificationsService();
const fuzAllocationService = new FuzAllocationService();

interface PropsInterface {
    FuzTypes: FuzTypeDTO[];
    refreshData: any;
    TeamsTreeData:any;
}

export default function FuzTypeContainer(props: PropsInterface) {
    const [fuzTypeCreateSurvey, setFuzTypeCreateSurvey] = useState(null);

    useEffect(() => {
        const getSurveyData = async () => {
            const data: any = await surveyService.getFuzTypeCreateSurvey()
            setFuzTypeCreateSurvey(data)
        }
        getSurveyData()
    }, []);


    async function createFuzType(data: FuzTypeCreateDTO) {
        const loadingCreateFuzType = notificationsService.loading("Creating Fuz Type...")
        console.log("container log",data)
        try {
            await fuzTypeService.createFuzType(data)
            notificationsService.updateLoadingSuccess(loadingCreateFuzType,"Successfully created Fuz Type")
            await props.refreshData()
        } catch (error) {
            console.error(error)
            notificationsService.updateLoadingError(loadingCreateFuzType,"Failed to create FuzType")
        }
    }

    async function deleteFuzType(FuzTypeId: string) {
        const loadingDeleteFuzType = notificationsService.loading("Deleting Fuz Type...")
        try {
            await fuzTypeService.deleteFuzType(FuzTypeId)
            notificationsService.updateLoadingSuccess(loadingDeleteFuzType,"Successfully deleted Fuz Type")
            await props.refreshData()
        } catch (error) {
            notificationsService.updateLoadingError(loadingDeleteFuzType,"Failed to delete FuzType")
        }
    }

    async function updateAllocation(FuzTypeId:string,Teams:string[]){
        const loadingUpdateAlloc = notificationsService.loading("Updating Allocation...")
        try {
            console.log("Updating alloc: ",FuzTypeId," => ",Teams)
            await fuzAllocationService.updateAllocationsForFuzType(FuzTypeId,Teams)
            notificationsService.updateLoadingSuccess(loadingUpdateAlloc,"Successfully updated allocation")
            await props.refreshData()
        } catch (error) {
            notificationsService.updateLoadingError(loadingUpdateAlloc,"Failed to update allocation")
        }
    }

    async function updateAttributeOfFuzType(FuzTypeId:string,AttributeName:string,AttributeValue:string){
        const loadingUpdateAlloc = notificationsService.loading("Updating Attribute...")
        try {
            console.log("Updating alloc: ",AttributeName," => ",AttributeValue)
            await fuzTypeService.updateAttribute(FuzTypeId,AttributeName,AttributeValue)
            notificationsService.updateLoadingSuccess(loadingUpdateAlloc,"Successfully updated allocation")
            await props.refreshData()
        } catch (error) {
            notificationsService.updateLoadingError(loadingUpdateAlloc,"Failed to update allocation")
        }
    }

    return (
        <FuzType
            FuzTypes={props.FuzTypes}
            FuzTypeCreateSurvey={fuzTypeCreateSurvey}
            createFuzType={(data: FuzTypeCreateDTO) => createFuzType(data)}
            deleteFuzType={(FuzTypeId: string) => deleteFuzType(FuzTypeId)}
            TeamsTreeData={props.TeamsTreeData}
            updateAllocation={(FuzTypeId:string,Teams:string[])=>updateAllocation(FuzTypeId,Teams)}
            updateAttributeOfFuzType={(FuzTypeId:string,AttributeName:string,AttributeValue:string)=>updateAttributeOfFuzType(FuzTypeId,AttributeName,AttributeValue)}
        />
    )

}