import { Result, Typography, Space } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';

import ButtonPrimary from '../Shared/Button.Primary'

interface PropsInterface {
    clearUploadState: any;
    errorMessage: string;
}

export default function ResultError(props: PropsInterface) {
    console.log(props.errorMessage)
    
    const errorArray = props.errorMessage.split(",");
    return (
        <Result
            status="error"
            title="Upload niet gelukt!"
            // subTitle="Insert subtitle."
            extra={[
                <ButtonPrimary text="Nieuwe Upload Poging" onClick={() => props.clearUploadState()} />
            ]}
        >
            <div className="desc">
                <Typography.Paragraph>
                    <Typography.Text
                        strong
                        style={{
                            fontSize: 16,
                        }}
                    >
                        Het bestand dat je probeerde te uploaden bevat de volgende errors:
                    </Typography.Text>
                </Typography.Paragraph>
                {errorArray.length > 0 &&
                    errorArray.map((error: string) => (
                        <Typography.Paragraph>
                            <Space>
                                <CloseCircleOutlined style={{ color: 'red' }} />
                                {error}
                            </Space>
                        </Typography.Paragraph>

                    ))
                }
            </div>
        </Result>
    )
}