
import { Button } from 'antd';
import styled from 'styled-components';
import {DownloadOutlined} from '@ant-design/icons'

const ButtonContainer = styled.div`
  .ant-btn-primary {
    background-color: #1c7074;
    border-color: #1c7074;

    &:hover {
        color: white;
        background-color: #2b979d;
    }
  }
`;

interface PropsInterface {
    text: string;
    onClick?: any;
    disabled?: boolean;
}

export default function ButtonDownload(props: PropsInterface) {
    return (
        <ButtonContainer>
            {props.disabled
                ? <Button disabled icon={<DownloadOutlined/>}>{props.text} </Button>
                : <Button onClick={props.onClick} type="primary" icon={<DownloadOutlined />}>{props.text}</Button>
            }
        </ButtonContainer>
    )
}