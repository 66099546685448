import { Modal,Button } from 'antd';
import SurveyComponent from '../../../Shared/SurveyComponent'

import {Survey} from '../../../Shared/Survey/Survey'

interface PropsInterface {
    modalVisible: boolean;
    setModalVisible:(value:boolean) => void;
    survey:Record<string,unknown>;
    createFuzType:(data:Record<string,unknown>) => void;
}
export default function FuzTypeCreateModal(props: PropsInterface) {

    function handleCancel() {
        props.setModalVisible(false);
    }

    async function onComplete(data:Record<string,unknown>){
        console.log("Survey Completed:",data)
        console.log(data.valuesHash)
        // await props.createFuzType(data.valuesHash)
    }

    return (
        <Modal
            title="Create Fuz Type"
            visible={props.modalVisible}
            onCancel={() => handleCancel()}
            footer={[
                <Button key="Cancel" onClick={() => handleCancel()}>
                    Cancel
                </Button>
            ]}
            width={800}
        >
            {/* {props.survey ?
                <SurveyComponent surveyData={props.survey} responseData={(data: any) => props.createFuzType(data)} />
                : ""
            } */}
             {props.survey ?
                <Survey  definition={props.survey} onComplete={(data: any) => props.createFuzType(data.valuesHash)}/>
                : ""
            }
        </Modal>
    )
}
