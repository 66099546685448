import HttpClient from "../../api/httpClient";
import { FuzTypeDTO, FuzTypeCreateDTO } from './FuzType.DTO'


const httpClient = new HttpClient();
const BASE_URL = process.env.REACT_APP_FUZ_API_URL

export default class FuzTypeApi {
    // Get all fuz types
    async getAllFuzTypes() {
        const url: string = BASE_URL + `/fuztype/`
        const data: FuzTypeDTO[] = await httpClient.performGetRequest(url)
        return data
    }

    // Get Fuz Type by id
    async getFuzTypeById(FuzTypeId: string) {
        const url: string = BASE_URL + `/fuztype/${FuzTypeId}`
        const results: any = await httpClient.performGetRequest(url)
        return results;
    }
    // Create Fuz Type
    async createFuzType(FuzType: FuzTypeCreateDTO) {
        const url: string = BASE_URL + `/fuztype/`
        const results: any = await httpClient.performPostRequest(url, FuzType)
        return results;
    }

    // Delete Fuz Type
    async deleteFuzType(FuzTypeId: string) {
        const url: string = BASE_URL + `/fuztype/${FuzTypeId}`
        const results: any = await httpClient.performDeleteRequest(url)
        return results;
    }

    // Update Attribute of Fuz Type   
    async patchUpdateAttribute(FuzTypeId: string, AttributeName: string,AttributeValue: string) {
        const url: string = BASE_URL + `/fuztype/${FuzTypeId}?attributeName=${AttributeName}&attributeValue=${AttributeValue}`
        const results: any = await httpClient.performPatchRequest(url)
        return results;
    }

    // Update Full Fuz Type
    async updateFullFuzType(FuzTypeId: string, FuzTypeBody:any){
        const url: string = BASE_URL + `/fuztype/${FuzTypeId}?attributeName=config`
        const results: any = await httpClient.performPatchRequestPayload(url,FuzTypeBody)
        return results;
    }

    // Get Fuz Events for Fuz Type
    async getFuzEventsForFuzType(FuzTypeId:string){
        const url:string = BASE_URL + `/fuzevent/?attributeName=FuzTypeId&attributeValue=${FuzTypeId}`
        const results: any = await httpClient.performGetRequest(url)
        return results;
    }

    // Get Distinct Users From Upload Events for specific Fuz Type
    async getDistinctUsersForFuzType(fuzTypeId:string){
        const url:string = BASE_URL + `/fuzevent/?attributeName=FuzTypeIdUsers&attributeValue=${fuzTypeId}`
        const results: any = await httpClient.performGetRequest(url)
        return results;
    }
}