import { useEffect, createContext, useContext, useState, useMemo, useCallback } from 'react';
import { isAfter } from 'date-fns';

import { Hub } from 'aws-amplify';
import { Auth } from '@aws-amplify/auth';
import { HubCapsule } from '@aws-amplify/core/lib-esm/Hub';
import { CognitoUserSession } from 'amazon-cognito-identity-js';

import { Spin, Typography } from 'antd';

import FullPageCenterContainer from '../components/Shared/FullPageCenterContainer';

interface AuthContextValue {
	id: string;
	name: string;
	given_name:string;
	email: string;
	groups: string[];
}

const AuthContext = createContext<AuthContextValue | null>(null);

export const useAuthContext = () => {
	const authContext = useContext(AuthContext);

	if (!authContext) {
		throw new Error('Cannot use useAuthContext outside of AuthProvider');
	}

	return authContext;
};

export const usePublicAuthContext = () => {
	return useContext(AuthContext);
};

interface Props {
	children?: React.ReactNode;
}
interface AuthState {
	session: CognitoUserSession | null;
	userAttributes: { [key: string]: any } | null;
}
function AuthProvider({ children }: Props) {
	const [{ session, userAttributes }, setAuthState] = useState<AuthState>({
		session: null,
		userAttributes: null,
	});

	// Configure auth event listener
	useEffect(() => {
		const authEventListener = async ({ payload }: HubCapsule) => {
			if (process.env.NODE_ENV === 'development') {
				console.log('Received following auth event: ', payload.event);
			}

			switch (payload.event) {
				case 'signOut':
				case 'signIn_failure':
				case 'tokenRefresh_failure':
					setAuthState({ session: null, userAttributes: null });
					return;
				case 'initialRender':
				case 'signIn':
				case 'tokenRefresh':
					const nextSession = await Auth.currentSession();
					const userInfo = await Auth.currentUserInfo();
					const nextUserAttributes: Record<string, unknown> = userInfo.attributes || {};
					setAuthState({ session: nextSession, userAttributes: nextUserAttributes });
					return;
			}
		};

		// Attach listener to amplify Auth events
		const listener = Hub.listen('auth', authEventListener);

		// Trigger listener for initial render
		authEventListener({ channel: 'fake', source: 'fake', payload: { event: 'initialRender' } });
		return () => Hub.remove('auth', listener);
	}, []);

	// Get tokens from session
	const { accessToken, idToken, refreshToken } = useMemo(() => {
		return {
			accessToken: session?.getAccessToken() || null,
			idToken: session?.getIdToken() || null,
			refreshToken: session?.getRefreshToken() || null,
		};
	}, [session]);

	const refreshAuth = useCallback(async () => {
		try {
			const currentUser = await Auth.currentAuthenticatedUser();
			const noop = () => undefined;
			currentUser.refreshSession(refreshToken, noop);
		} catch (e) {
			if (process.env.NODE_ENV === 'development') {
				console.error('Session refresh failed. Signin out...');
			}
			Auth.signOut();
		}
	}, [refreshToken]);

	const tokenExpDate = useMemo(() => {
		if (!accessToken || !idToken) return;
		const accessTokenExp = accessToken?.payload?.exp;
		const identityTokenExp = idToken?.payload?.exp;

		const REFRESH_OFFSET = 10; //seconds
		const expirationInSeconds = Math.min(accessTokenExp, identityTokenExp) - REFRESH_OFFSET;

		return new Date(expirationInSeconds * 1000);
	}, [accessToken, idToken]);

	// Setup trigger to refresh user when tokens expire
	useEffect(() => {
		if (!tokenExpDate) return;

		const currentTime = new Date().getTime();
		const expTime = tokenExpDate?.getTime() - currentTime;
		if (process.env.NODE_ENV === 'development') {
			console.log(`Refreshing user in ${expTime / 1000} seconds`);
		}

		const timeoutRef = setTimeout(refreshAuth, expTime);

		return () => clearTimeout(timeoutRef);
	}, [tokenExpDate, refreshAuth]);

	// Setup trigger to refresh user when page is focused
	useEffect(() => {
		if (!tokenExpDate) return;

		const refreshTokenIfExpired = async () => {
			if (isAfter(tokenExpDate, new Date())) return;
			if (process.env.NODE_ENV === 'development') {
				console.log('Refreshing user due to page focus');
			}
			await refreshAuth();
		};

		window.addEventListener('visibilitychange', refreshTokenIfExpired);
		window.addEventListener('focus', refreshTokenIfExpired);

		return () => {
			window.removeEventListener('visibilitychange', refreshTokenIfExpired);
			window.removeEventListener('focus', refreshTokenIfExpired);
		};
	}, [tokenExpDate, refreshAuth]);

	// Get auth headers
	// const authHeaders = useMemo(() => {
	// 	if (!accessToken || !idToken) return;

	// 	return {
	// 		'X-Access-Token': `Bearer ${accessToken?.getJwtToken()}`,
	// 		'X-Id-Token': `Bearer ${idToken?.getJwtToken()}`,
	// 	};
	// }, [accessToken, idToken]);

	// Extract groups from accesstoken
	const groups = useMemo(() => {
		return (accessToken?.payload['cognito:groups'] as string[]) || [];
	}, [accessToken?.payload]);

	// Get additional user info
	const { id, name,given_name, email } = useMemo(() => {
		const id = (userAttributes?.sub as string) || '';
		const name = (userAttributes?.name as string) || '';
		const given_name = (userAttributes?.given_name as string) || '';
		const email = (userAttributes?.email as string) || '';
		return { id, name,given_name, email };
	}, [userAttributes]);

	if (!session) {
		return (
			<FullPageCenterContainer>
				<Spin tip="Wachten op authenticatie gegevens" />
				<Typography.Text>
					Klik <a href="/">hier</a> om de pagina te herladen.
				</Typography.Text>
			</FullPageCenterContainer>
		);
	}

	return (
		<AuthContext.Provider value={{ id, name,given_name, email, groups }}>{children}</AuthContext.Provider>
	);
}

export default AuthProvider;
