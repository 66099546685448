import { useState } from "react";
import { Upload, message, Typography, Divider } from "antd";
import { InboxOutlined } from "@ant-design/icons";

// Domain
import { FuzTypeDTO } from "../../domain/FuzType/FuzType.DTO";
import { FuzUploadDTO } from "../../domain/FuzType/FuzUpload.DTO";

// Services
import NotificationsService from "../Shared/Notifications";

// Components
import ResultSuccess from "./Upload.Result.Success";
import ResultError from "./Upload.Result.Error";
import UploadList from "./Upload.UploadList";
import UploadRulesCsv from "./Upload.Rules.Csv";
import UploadRulesExcel from "./Upload.Rules.Excel";

const { Dragger } = Upload;
const { Title } = Typography;

const notificationsService = new NotificationsService();

interface PropsInterface {
  fuzType: FuzTypeDTO;
  uploadFile: any;
  userUploads: FuzUploadDTO[];
}

export default function UploadZone(props: PropsInterface) {
  const FuzType: FuzTypeDTO = props.fuzType;
  const [uploadState, setUploadState] = useState("");
  const [uploadError, setUploadError] = useState("");

  const draggerProps = {
    name: "file",
    multiple: false,
    accept: FuzType.AllowedFileTypes,
    showUploadList: false,
    customRequest(e: any) {
      console.log("Custom Request");
      console.log(e);
      Uploader(props.fuzType, e);
    },
    onChange(info: any) {
      const status = info.file.status;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    beforeUpload(file: any) {
      console.log("Before Upload Check: ", file);
      const maxSize = props.fuzType.MaxFileUploadSizeMb * 1024 * 1024;
      if (file.size > maxSize) {
        message.error(`${file.name}: File Size is to large!`);
        return Upload.LIST_IGNORE;
      } else {
        return true;
      }
    },
  };

  async function Uploader(FuzType: FuzTypeDTO, file: any) {
    const loadingUpload = notificationsService.loading("Uploaden ...");
    try {
      await props.uploadFile(FuzType, file);
      notificationsService.updateLoadingSuccess(
        loadingUpload,
        `Upload Succesvol ${file.file.name}`
      );
      setUploadState("success");
    } catch (error) {
      notificationsService.updateLoadingError(
        loadingUpload,
        `Upload Gefaald ${file.file.name}`
      );
      const myError: any = error;
      console.log(myError.response.data);
      setUploadError(myError.response.data.Error);
      setUploadState("error");
    }
  }

  async function clearUploadState() {
    setUploadState("");
    setUploadError("");
  }

  return (
    <div>
      <Title level={3}>{FuzType.TypeName}</Title>
      {FuzType.UploadDescription ? (
        <div>
          <Divider />
          <p>{FuzType.UploadDescription}</p>
        </div>
      ) : (
        ""
      )}
      {uploadState === "" ? (
        <Dragger {...draggerProps}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Upload het document</p>
          <p className="ant-upload-hint">
            Klik of sleep het bestand naar dit gebied om het te uploaden.
          </p>
        </Dragger>
      ) : uploadState === "success" ? (
        <ResultSuccess clearUploadState={() => clearUploadState()} />
      ) : uploadState === "error" ? (
        <ResultError
          clearUploadState={() => clearUploadState()}
          errorMessage={uploadError}
        />
      ) : (
        "No case"
      )}

      {FuzType.AllowedFileTypes ? (
        <div>
          <Divider />
          <Title level={5}>Toegestane bestandstypes:</Title>
          <p>{props.fuzType.AllowedFileTypes}</p>
        </div>
      ) : (
        ""
      )}
      {FuzType.ValidationSchemas && FuzType.ValidationSchemas.length > 0 ? (
        <div>
          <Divider />
          {FuzType.ValidationSchemas.map((ValidationSchema: any, i: number) =>
            ValidationSchema.ValidationSchemaType === "csv" ? (
              <UploadRulesCsv uploadRules={ValidationSchema} />
            ) : ValidationSchema.ValidationSchemaType === "excel" ? (
              <UploadRulesExcel uploadRules={ValidationSchema} />
            ) : (
              ""
            )
          )}
        </div>
      ) : (
        ""
      )}
      <div>
        <UploadList UserUploads={props.userUploads} />
      </div>
    </div>
  );
}
