import { Redirect, Route,RouteProps } from "react-router-dom";

import { useAuthContext } from "../auth/AuthenticationContext";


interface ProtectedRouteProps extends RouteProps {
	redirectPath: string;
	allowedFor: string[];
}

export default function ProtectedRoute({ redirectPath, allowedFor, ...props }: ProtectedRouteProps) {
	const { groups } = useAuthContext();
	const isAllowed = allowedFor.some((group) => groups.includes(group));

	if (!isAllowed) return <Redirect to={redirectPath} />;

	return <Route {...props} />;
}
