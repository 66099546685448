import { UserDTO,UserCreateDTO } from "./User.DTO";
import HttpClient from "../../api/httpClient";

const httpClient = new HttpClient();
const BASE_URL = process.env.REACT_APP_USER_MANAGEMENT_URL

export default class UserApi{
    // Get All Users
    async getAllUsers():Promise<UserDTO[]>{
        const url:string = BASE_URL + `/user`;
        const data:UserDTO[] = await httpClient.performGetRequest(url)
        return data
    }

    //Get User by Id
    async getUser(userId:string):Promise<UserDTO>{
        const url:string = BASE_URL + `/user/${userId}`;
        const data:UserDTO = await httpClient.performGetRequest(url)
        return data
    }

    //Create User
    async createUser(userData:UserCreateDTO){
        const url:string = BASE_URL + `/user`;
        const result:any = await httpClient.performPostRequest(url,userData)
        return result
    }

    // Delete User
    async deleteUser(userId:string){
        const url:string = BASE_URL + `/user/${userId}`;
        const data:UserDTO = await httpClient.performDeleteRequest(url)
        return data
    }

    // Update Teams of user
    async updateTeamsOfUser(userId:string,allocations:string[]){
        const url:string = BASE_URL + `/user/users/${userId}?updateType=teams`;
        const data:any = await httpClient.performPatchRequestPayload(url,allocations)
        return data
    }
}