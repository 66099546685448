import axios from 'axios';
import AuthHelper from '../auth/AuthHelper'
const authHelper = new AuthHelper();

export default class HttpClient {
    async performGetRequest(url: string) {
        const cognitoCurrentSession = await authHelper.getAuthInfo()
        const response = await axios.get(url, {
            headers: {
                'X-Id-Token': cognitoCurrentSession.signInUserSession.idToken.jwtToken,
                'x-access-token': cognitoCurrentSession.signInUserSession.accessToken.jwtToken
            }
        });
        return await response.data
    }

    async performPostRequest(url: string, payloadData: any) {
        const cognitoCurrentSession = await authHelper.getAuthInfo()
        const response = await axios.post(url, payloadData, {

            headers: {
                'X-Id-Token': cognitoCurrentSession.signInUserSession.idToken.jwtToken,
                'x-access-token': cognitoCurrentSession.signInUserSession.accessToken.jwtToken
            }
        });
        return await response.data
    }
    async performPostRequestFile(url: string, payloadData: any) {
        const cognitoCurrentSession = await authHelper.getAuthInfo()
        const response = await axios.post(url, payloadData, {

            headers: {
                'X-Id-Token': cognitoCurrentSession.signInUserSession.idToken.jwtToken,
                'x-access-token': cognitoCurrentSession.signInUserSession.accessToken.jwtToken,
                // 'Content-Type': 'multipart/form-data;'
            }
        });
        return await response.data
    }
    async performPostRequestNoPayload(url: string) {
        const cognitoCurrentSession = await authHelper.getAuthInfo()

        const response = await axios.post(url, "", {

            headers: {
                'X-Id-Token': cognitoCurrentSession.signInUserSession.idToken.jwtToken,
                'x-access-token': cognitoCurrentSession.signInUserSession.accessToken.jwtToken
            }
        });
        return await response.data
    }

    async performDeleteRequest(url: string) {
        const cognitoCurrentSession = await authHelper.getAuthInfo()

        const response = await axios.delete(url, {

            headers: {
                'X-Id-Token': cognitoCurrentSession.signInUserSession.idToken.jwtToken,
                'x-access-token': cognitoCurrentSession.signInUserSession.accessToken.jwtToken
            }
        });
        return await response.data
    }

    async performPatchRequest(url: string) {
        const cognitoCurrentSession = await authHelper.getAuthInfo()

        const response = await axios.patch(url, "", {

            headers: {
                'X-Id-Token': cognitoCurrentSession.signInUserSession.idToken.jwtToken,
                'x-access-token': cognitoCurrentSession.signInUserSession.accessToken.jwtToken
            }
        });
        return await response.data
    }

    async performPatchRequestPayload(url: string, payloadData: any) {
        const cognitoCurrentSession = await authHelper.getAuthInfo()

        const response = await axios.patch(url, payloadData, {

            headers: {
                'X-Id-Token': cognitoCurrentSession.signInUserSession.idToken.jwtToken,
                'x-access-token': cognitoCurrentSession.signInUserSession.accessToken.jwtToken
            }
        });
        return await response.data
    }

    async performPutRequest(url: string) {
        const cognitoCurrentSession = await authHelper.getAuthInfo()

        const response = await axios.put(url, {

            headers: {
                'X-Id-Token': cognitoCurrentSession.signInUserSession.idToken.jwtToken,
                'x-access-token': cognitoCurrentSession.signInUserSession.accessToken.jwtToken
            }
        });
        return await response.data
    }

    async performUploadS3(url: string, contentType: string, data: any) {
        const response = await axios.put(url, data, {
            headers: {
                'Content-Type': contentType
            }
        });
        return await response.data
    }
}




