import { UserDTO } from '../User/User.DTO';
import { FuzTypeDTO } from './FuzType.DTO';
import { FuzUploadDTO } from './FuzUpload.DTO';

import FuzUploadApi from './FuzUpload.Api'

const fuzUploadApi = new FuzUploadApi();


export default class FuzUploadService {
    // Upload the file
    async uploadFile(FuzType: FuzTypeDTO, UserInfo: UserDTO, File: any) {
        const data = new FormData();
        data.append('file', File.file);
        return await fuzUploadApi.uploadFile(FuzType.TypeId, UserInfo.UserId, data)
    }

    // Get Uploads For A User
    async getUploadsForUser(UserId: string) {
        const fuzUploads:FuzUploadDTO[] = await fuzUploadApi.getFuzUploadsForUser(UserId);
        return fuzUploads
    }

    // Get specific Uploads for a User
    async filterUploadsByType(FuzUploads: FuzUploadDTO[], FuzTypeId: string) {
        const FuzUploadsFiltered: FuzUploadDTO[] = FuzUploads.filter(f => f.FuzTypeId === FuzTypeId)
        return FuzUploadsFiltered
    }

    // Get Specic Upload
    async getUpload(userId:string,uploadId:string){
        const data:FuzUploadDTO = await fuzUploadApi.getFuzUpload(userId,uploadId)
        return data
    }
}