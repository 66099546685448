import HttpClient from "../../api/httpClient";
import {FuzUploadDTO} from './FuzUpload.DTO'

const httpClient = new HttpClient();
const BASE_URL = process.env.REACT_APP_FUZ_API_URL

export default class FuzUploadApi {

    // Upload the file
    async uploadFile(FuzTypeId: string,UserId:string,File:any) {
        console.log("Upload statge")
        const url: string = BASE_URL + `/fuzupload/${FuzTypeId}?userId=${UserId}`
        console.log(url)
        const results: any = await httpClient.performPostRequestFile(url, File)
        return results;
    }

    // Get Fuz Uploads For User
    async getFuzUploadsForUser(UserId: string) {
        const url: string = BASE_URL + `/fuzupload/${UserId}`
        const results: FuzUploadDTO[] = await httpClient.performGetRequest(url)
        return results;
    }

    // Get Fuz Upload
    async getFuzUpload(userId: string,uploadId: string){
        const url: string = BASE_URL + `/fuzupload/${userId}/${uploadId}`
        const results: FuzUploadDTO = await httpClient.performGetRequest(url)
        return results;
    }
  
}