import { useEffect, useMemo, useRef } from 'react';
import { Survey as SurveyJsSurvey, SurveyModel, Model } from 'survey-react';

import ErrorBoundary from '../ErrorBoundary';
import Alert from '../Alert';

import { SurveyWrapper } from './SurveyWrapper';

import './SurveyJs.config';

interface Props {
	definition?: Record<string, unknown>;
	data?: Record<string, unknown>;
	readonly?: boolean;
	onComplete?: (sender: SurveyModel, options: any) => any;
	onValueChanged?: (sender: SurveyModel, options: any) => any;
	onAfterRenderPage?: (sender: SurveyModel, options: any) => any;
}

export function Survey(props: Props) {
	const surveyRef = useRef<any>(null);

	const { definition, data, readonly, onComplete, onValueChanged, onAfterRenderPage } = props;

	const survey = useMemo(() => {
		const s = new Model(definition);
		s.css = {
			navigationButton: 'ant-btn ant-btn-primary',
			paneldynamic: { button: 'ant-btn ant-btn-primary' },
		};
		return s;
	}, [definition]);

	useEffect(() => {
		survey.mode = readonly ? 'display' : 'edit';
	}, [readonly, survey]);

	useEffect(() => {
		if (typeof onValueChanged !== 'function') return;
		survey.onValueChanged.add(onValueChanged);
		return () => survey.onValueChanged.remove(onValueChanged);
	}, [onValueChanged, survey.onValueChanged]);

	useEffect(() => {
		if (typeof onAfterRenderPage !== 'function') return;
		survey.onAfterRenderPage.add(onAfterRenderPage);
		return () => survey.onAfterRenderPage.remove(onAfterRenderPage);
	}, [onAfterRenderPage, survey.onAfterRenderPage]);

	useEffect(() => {
		if (!data) return;
		// When there is initial data, overwrite all fields from default survey with the initial data
		survey.data = { ...survey.data, ...data };
	}, [data, survey]);

	return (
		<ErrorBoundary
			fallback={
				<Alert
					type="error"
					message="Er is iets fout gegaan bij het laden van de survey."
					showIcon
				/>
			}
		>
			<SurveyWrapper>
				<SurveyJsSurvey ref={surveyRef} model={survey} onComplete={onComplete} />
			</SurveyWrapper>
		</ErrorBoundary>
	);
}
