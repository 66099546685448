import { FuzTypeDTO } from '../FuzType/FuzType.DTO';
import FuzAllocationApi from './FuzAllocation.Api';

const fuzAllocationApi = new FuzAllocationApi();

export default class FuzAllocationService{
    // Get Allocations for FuzTypeId
    async getAllocationsForFuzType(FuzTypeId:string){
        const data:string[] = await fuzAllocationApi.getAllocationsForFuzType(FuzTypeId);
        return data
    }

    // Update Allocations For Fuz Type
    async updateAllocationsForFuzType(FuzTypeId:string, Allocations:string[]){
        const response:any = await fuzAllocationApi.updateAllocationsForFuzType(FuzTypeId, Allocations)
        return response
    }

    // Get Fuz Types from Teams
    async getFuzTypesFromTeams(Teams:string[]){
        const response:FuzTypeDTO[] = await fuzAllocationApi.getFuzTypesFromTeamKeys(Teams)
        return response
    }
}