import React from 'react';

interface State {
	hasError: boolean;
}
class ErrorBoundary extends React.Component<{ fallback?: React.ReactNode }, State> {
	state = { hasError: false };

	static getDerivedStateFromError() {
		return { hasError: true };
	}

	componentDidCatch(error: any, errorInfo: any) {
		console.error(error, errorInfo);
	}

	render() {
		if (this.state.hasError) {
			if (this.props.fallback) return this.props.fallback;
			return 'Er is iets fout gegaan.';
		}

		return this.props.children;
	}
}
export default ErrorBoundary;
