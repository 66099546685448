import $ from 'jquery';
import 'select2';
import 'select2/dist/css/select2.min.css';
import 'inputmask';

//@ts-ignore
import * as widgets from 'surveyjs-widgets';
import * as Survey from 'survey-react';

// Widgets
widgets.select2(Survey);
widgets.select2tagbox(Survey, $);
widgets.inputmask(Survey);


Survey.FunctionFactory.Instance.register('log', (args: any[]) => {
	console.log(args);
});