import { useState, useEffect } from 'react'
import styled from 'styled-components';
import { Tabs, Spin } from 'antd';

// Components
import FuzTypeContainer from '../components/Admin/FuzTypes/FuzType.Container'
import { FuzTypeDTO } from '../domain/FuzType/FuzType.DTO';
import { TeamDTO } from '../domain/Team/Team.DTO'
import NotificationsService from '../components/Shared/Notifications';

// Services
import FuzTypeService from '../domain/FuzType/FuzType.Service';
import TeamService from '../domain/Team/Team.Service'
import { UserDTO } from '../domain/User/User.DTO';

const fuzTypeService = new FuzTypeService();
const teamService = new TeamService();
const notificationService = new NotificationsService();


const { TabPane } = Tabs;
const TabsContainer = styled.div`
  .ant-tabs-tab.ant-tabs-tab-active {
  border-bottom: 2px solid #1c7074;
  color: #1c7074;
  z-index: 2;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color:#1c7074;
}
`;

interface Propsinterface {
    userInfo: UserDTO;
}

export default function AdminRoute(props: Propsinterface) {
    const [loading, setLoading] = useState(false)
    const [FuzTypes, setFuzTypes] = useState<FuzTypeDTO[]>([]);
    const [Teams, setTeams] = useState<TeamDTO[]>([]);

    useEffect(() => {
        const getData = async () => {
            setLoading(true);
            // Get Fuz Types
            try {
                const fuzTypes: FuzTypeDTO[] = await fuzTypeService.getFuzTypes()
                console.log("Fuz Types: ", fuzTypes)
                console.table(fuzTypes)
                setFuzTypes(fuzTypes)
            } catch (error) {
                notificationService.error("Error Loading Fuz Types")
            }
            // Get Teams
            const teams: TeamDTO[] = await teamService.getTeamTree()
            console.log("Teams: ", teams)
            setTeams(teams)
            setLoading(false)
        }
        getData()

    }, []);

    async function refreshData() {
        const fuzTypes: FuzTypeDTO[] = await fuzTypeService.getFuzTypes()
        console.log("Fuz Types: ", fuzTypes)
        setFuzTypes(fuzTypes)
    }


    return (
        <div>
            <div style={divStyle}>
                <TabsContainer>
                    <Tabs defaultActiveKey="1" >
                        <TabPane tab="Fuz Types" key="1">
                            <Spin spinning={loading} />
                            <FuzTypeContainer FuzTypes={FuzTypes} refreshData={() => refreshData()} TeamsTreeData={Teams} />
                        </TabPane>
                        {/* <TabPane tab="Fuz Types_v2" key="2">
                       <p>V2</p>
                    </TabPane> */}
                    </Tabs>
                </TabsContainer>
            </div>
        </div>
    )
}


const divStyle = {
    padding: "3em"
}
