import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { CloudUploadOutlined, LockOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';

import { useAuthContext } from "../../auth/AuthenticationContext";

const HomeContainer = styled.div`
min-height: 55vh;
display: grid;
grid-template-rows: 1fr;
grid-template-columns: 1fr;
align-items: center;
`;

const AppLink = styled(Link)`
display: flex;
flex-direction: column;
align-items: center;
text-align: center;
justify-items: center;
margin: 1rem;
padding: 1rem;
color: white;
font-weight: bold;
background-color: #1c7074;
border-radius: 0.5rem;
box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);

&:hover {
    color: white;
    background-color: #2b979d;
}
`;
const UploadIcon = styled(CloudUploadOutlined)`
	font-size: 3rem;
	margin-bottom: 1rem;
`;

const AdminIcon = styled(LockOutlined)`
	font-size: 3rem;
	margin-bottom: 1rem;
`;



export default function Home() {
    const { groups } = useAuthContext();
	const isInAdminGroup = groups.includes("Admin");
    return (
        <HomeContainer>
            <Row justify="center">
                <Col xs={24} lg={8} xl={6} xxl={3}>
                    <AppLink to="/upload">
                        <UploadIcon />
                        <span>Upload Zone</span>
                    </AppLink>
                </Col>
                {isInAdminGroup ?
                    <Col xs={24} lg={8} xl={6} xxl={3}>
                        <AppLink to="/admin">
                            <AdminIcon />
                            <span>Admin</span>
                        </AppLink>
                    </Col>
                    : ""
                }
            </Row>
        </HomeContainer>
    );
}