export enum FuzEventStatus {
  SUCCESS = "SUCCESS",
  FAILURE = "FAILURE",
}

export interface CreateFuzEventDTO {
  FuzTypeId: string;
  UserId: string;
  UploadId?: string;
  FuzTypeName: string;
  Status: FuzEventStatus;
  Comment?: string;
}

export interface FuzEventDTO {
  FuzTypeId: string;
  EventId: string;
  UserId: string;
  UploadId?: string;
  FuzTypeName: string;
  Status: FuzEventStatus;
  CreatedAt: string;
  Comment?: string;
}

export interface FuzEventDistinctUsersDTO {
  UserId: string;
}