import {Typography, Table,Row,Col } from 'antd';

interface UploadRule{
    name:string;
    type:string;
    required:string;
    null_or_empty:string;
}

interface PropInterface {
    uploadRules: any;
}

export default function UploadRulesCsv(props: PropInterface) {
    const columnDefinitions:UploadRule[] =  props.uploadRules.ColumnDefinitions;


    const columns = [
        {
            title: 'Kolom Naam',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Kolom Type',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'Verplicht',
            dataIndex: 'required',
            key: 'required',
            render: (text: any) => <p>{text.toString()}</p>,
        },
        {
            title: 'Kan leeg of null zijn',
            dataIndex: 'null_or_empty',
            key: 'null_or_empty',
            render: (text: any) => <p>{text.toString()}</p>,
        },
        
    ];


    return (
        <div>
            <Typography.Title level={5}>Richtlijnen CSV:</Typography.Title>
            <p>Specifieke richtlijnen voor csv bestanden.</p>
            <Row>
                <Col span={4}></Col>
                <Col span={16}>
                    <Table dataSource={columnDefinitions} columns={columns} size="small" pagination={false}/>
                </Col>
                <Col span={4}></Col>
            </Row>
        </div>
    )
}