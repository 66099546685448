import { Auth } from 'aws-amplify';

export default class AuthHelper {
  async getAuthInfo() {
    return await Auth.currentAuthenticatedUser()
  }

  // Check if user is in admin group
  async isAdmin() {
    const authInfo = await this.getAuthInfo();
    try {
      return (authInfo.signInUserSession.accessToken.payload["cognito:groups"].indexOf("Admin") > -1);
    } catch (error) {
      return false;
    }
  }

  async getCognitoGroups(){
    try {
      const authInfo = await this.getAuthInfo();
      return (authInfo.signInUserSession.accessToken.payload["cognito:groups"]);
    } catch (error) {
      return false;
    }
  }

  async signOut(){
    await Auth.signOut();
  }
}
