import { UserCreateDTO, UserDTO } from './User.DTO'

import UserApi from './User.Api';
const userApi = new UserApi();

export default class UserService {
    // Get All Users
    async getUsers() {
        const users = await userApi.getAllUsers()
        return users
    }

    // Get User By UserId
    async getUser(userId: string) {
        const user: UserDTO = await userApi.getUser(userId)
        return user
    }

    // Create User
    async createUser(userData:UserCreateDTO){
        const result = await userApi.createUser(userData)
        return result
    }

    // Delete User
    async deleteUser(userId: string) {
        const user: UserDTO = await userApi.deleteUser(userId)
        return user
    }

    // Update Teams of user
    async updateTeamsOfUser(userId: string,allocations:string[]){
        return await userApi.updateTeamsOfUser(userId,allocations)
    } 
}
