import { Modal, Button, Input, Form, Space } from 'antd';
import { useState } from 'react';
import ButtonPrimary from '../../../Shared/Button.Primary';


interface PropsInterface {
    modalVisible: any;
    setModalVisible: any;
    attributeName: string;
    updateAttributeFuzType: any
}
export default function FuzTypeUpdateAttributeModal(props: PropsInterface) {
    const [form] = Form.useForm();
    const [atttributeValue, setAttributeValue] = useState("")

    function handleCancel() {
        setAttributeValue("")
        form.resetFields();
        props.setModalVisible(false)
    }

    const onChange = (e: any) => {
        console.log('Change:', e.target.value);
        setAttributeValue(e.target.value)
    };

    async function onSave() {
        await props.updateAttributeFuzType(props.attributeName, atttributeValue)
        setAttributeValue("")
        form.resetFields();
        props.setModalVisible(false)
    }

    return (
        <Modal
            title="Update Fuz Type"
            visible={props.modalVisible}
            onCancel={() => handleCancel()}
            footer={[
                <Space>
                    <Button key="Cancel" onClick={() => handleCancel()}>
                        Cancel
                    </Button>
                    <ButtonPrimary text="Save" onClick={() => onSave()} />
                </Space>

            ]}
        >
            <Form
                name="basic"
                onChange={onChange}
                form={form}
            >
                <Form.Item
                    label={props.attributeName}
                    name={props.attributeName}
                >
                    <Input.TextArea />
                </Form.Item>
            </Form>

        </Modal>
    )
}
