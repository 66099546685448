// Components
import { List, Typography, Divider } from 'antd';
import ButtonDownload from "../Shared/Button.Download"

// Utils
import { formatBytes } from '../../utils/FileSizeHelper'
import NotificationsService from '../Shared/Notifications'

// Domain
import { FuzUploadDTO } from "../../domain/FuzType/FuzUpload.DTO";
import { FuzDownloadDTO } from '../../domain/FuzDownload/FuzDownload.DTO'
import FuzDownloadService from '../../domain/FuzDownload/FuzDownload.Service'

const fuzDownloadService = new FuzDownloadService();
const notificationsService = new NotificationsService();

interface PropsInterface {
    UserUploads: FuzUploadDTO[]
}

export default function UploadList(props: PropsInterface) {
    const UserUploads = props.UserUploads;

    async function onClickDownload(userUpload: FuzUploadDTO) {
        const loading = notificationsService.loading("Uploaden ...")
        try {
            const fuzDownload: FuzDownloadDTO = await fuzDownloadService.getFuzDownload(userUpload.UserId, userUpload.UploadId)
            notificationsService.updateLoadingSuccess(loading, `Download Succesvol ${fuzDownload.FileName}`)
            const link = document.createElement('a');
            link.href = fuzDownload.S3_PSIGN_URL;
            link.setAttribute(
                'download',
                fuzDownload.FileName,
            );
            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link.parentNode?.removeChild(link);
        } catch (error) {
            notificationsService.updateLoadingError(loading, `Download Gefaald`)
        }

    }

    return (
        <div>
            {UserUploads && UserUploads.length > 0 ?
                <div>
                    <Divider />
                    <Typography.Title level={5}>Vorige Uploads:</Typography.Title>
                    <List
                        itemLayout="horizontal"
                        dataSource={UserUploads}
                        renderItem={item => (
                            <List.Item
                                actions={[<ButtonDownload text='Download' onClick={() => onClickDownload(item)} />]}
                            >
                                <List.Item.Meta
                                    title={`${item.FileName}`}
                                    description={new Date(item.CreatedAt).toLocaleString()}
                                />
                                <div>Grootte: {formatBytes(item.FileSize, 2)}</div>
                                {/* <a href="https://www.orimi.com/pdf-test.pdf" target="_blank" download="test.pdf">Get PDF</a> */}
                            </List.Item>
                        )}
                    />
                </div>
                : ""}
        </div>
    )
}