import { Link } from 'react-router-dom';
import './NavBar.css'

import { Button, Space, Dropdown, Menu,Avatar } from 'antd';
import { LogoutOutlined, UserOutlined } from '@ant-design/icons';

import { useAuthContext } from "../../auth/AuthenticationContext";
import AuthHelper from '../../auth/AuthHelper'
const authHelper = new AuthHelper();

interface PropsInterface {
  title: string;
}

export default function NavBar(props: PropsInterface) {
  const { email } = useAuthContext();

  async function signOut() {
    await authHelper.signOut()
  }

  const menu = (
    <Menu>
      <Menu.Item onClick={signOut}>
        <Button icon={<LogoutOutlined />} type="text">Log Uit</Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="nav-bar">
      <Space>
        <div>
          <Link to="/" >
            <Space direction="vertical">
              <img src={process.env.PUBLIC_URL + '/logo_vazg_big.jpg'} width="180" height="60" alt='Logo Vlaams Agentschap Zorg en Gezondheid'/>
            </Space>
          </Link>
        </div>
        <p className="nav-link">{props.title}</p>
        {process.env.REACT_APP_ENV === "dev"
          ? <p className="nav-link">DEVELOPMENT OMGEVING</p>
          : process.env.REACT_APP_ENV === "uat" ?
            <p className="nav-link">ACCEPTATIE OMGEVING</p>
            : ""
        }
      </Space>
      <div>
        <Dropdown overlay={menu} placement="bottomRight" arrow>
          <Space>
            <p className="nav-link">{email}</p>
            <Avatar size="large" icon={<UserOutlined />} />
          </Space>
        </Dropdown>
      </div>
    </div >
  )
}
