import { ReactNode } from 'react';
import styled, { createGlobalStyle, css } from 'styled-components';

export const GlobalSurveyCss = createGlobalStyle`
  .sv_window {
    position: fixed;
    bottom: 3px;
    right: 10px;
    background-color: var(--survey-js-color-primary-3);
    padding: 1px;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    word-wrap: normal;
    white-space: normal;
    background-color: var(--survey-js-color-grey-5);
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid var(--survey-js-color-gray-1);
    border: 1px solid var(--survey-js-color-darken-2);
    border-radius: 6px;
    -webkit-box-shadow: 0 5px 10px var(--survey-js-color-darken-2);
    box-shadow: 0 5px 10px var(--survey-js-color-darken-2);
    line-break: auto;
    z-index: 100;

    .sv_window_title {
      padding: 8px 14px;
      margin: 0;
      font-size: 14px;
      background-color: var(--survey-js-color-gray-4);
      border-bottom: 1px solid var(--survey-js-color-gray-3);
      border-radius: 5px 5px 0 0;

      a,
      a:link,
      a:visited {
        text-decoration: none;
        font-size: 14px;
        font-style: normal;
        color: var(--survey-js-color-gray-0);
      }
    }

    .sv_window_content {
      padding: 8px;
      margin: 0;
    }
  }
`;

const select2Css = css`
	input.select2-search__field {
		border: none !important;
	}
	.select2-container--default.select2-container--focus .select2-selection--multiple {
		border-color: var(--survey-js-color-primary-3);
	}
`;

const SurveyWrapperWithStyles = styled.div`
	${select2Css}

	position: relative;
	width: 100%;

	// Fix ant design buttons
	.ant-btn:empty {
		width: auto;
		visibility: visible;
	}

	.sv_frame .sv_container {
		max-width: 80%;
		margin: auto;
		padding: 0 1em;

		.sv_header {
			padding-top: 5em;
			padding-bottom: 1em;
		}
	}

	hr {
		border-bottom: 1px solid var(--survey-js-color-gray-3);
	}

	input[type='button'],
	button {
		color: var(--survey-js-color-gray-5);
		background-color: var(--survey-js-color-primary-3);
		appearance: none;
		-webkit-appearance: none;
		-moz-appearance: none;
		font-size: 0.85em;
		font-weight: bold;
		line-height: 2em;
		border: none;
		min-width: 100px;
		cursor: pointer;
		padding: 0 2em;
		border-radius: 2px;
	}

	input[type='button']:hover,
	button:hover {
		background-color: var(--survey-js-color-primary-2);
	}

	.sv_q_other input,
	.sv_q_text_root,
	.sv_q_dropdown_control,
	input:not([type='button']):not([type='reset']):not([type='submit']):not([type='image']):not([type='checkbox']):not([type='radio']),
	select,
	textarea {
		appearance: none;
		-webkit-appearance: none;
		-moz-appearance: none;
		border: 1px solid var(--survey-js-color-gray-3);
		color: var(--survey-js-color-grey-1);
		opacity: 1;

		&:focus {
			border: 1px solid var(--survey-js-color-primary-3);
		}
	}

	.sv_select_wrapper {
		background-color: var(--survey-js-color-gray-5);
	}

	.sv_select_wrapper:before {
		background-color: var(--survey-js-color-primary-3);
	}

	.sv_header {
		color: var(--survey-js-color-grey-1);
	}

	.sv_custom_header {
		position: absolute;
		width: 100%;
		height: 74%;
		max-height: 275px;
	}

	.sv-progress__bar {
		background-color: var(--survey-js-color-primary-3);
	}

	.sv_p_root {
		.sv_row .sv_q.sv_qstn:first-child:last-child {
			flex: none !important;
		}

		> .sv_row {
			border-bottom: 1px solid var(--survey-js-color-gray-3);
			&:nth-child(odd) {
				background-color: var(--survey-js-color-row-odd);
			}
			&:nth-child(even) {
				background-color: var(--survey-js-color-row-even);
			}
		}
	}

	.sv_q_erbox:not([style*='display: none']):not([style*='display:none']) {
		border: 1px solid var(--survey-js-color-danger);
		color: var(--survey-js-color-danger);

		+ input,
		+ textarea,
		+ .sv_select_wrapper select {
			border: 1px solid var(--survey-js-color-danger);
			&:focus {
				outline: var(--survey-js-color-danger) auto 5px;
			}
		}
	}

	table {
		&.sv_q_matrix tr,
		&.sv_q_matrix_dropdown tr,
		&.sv_q_matrix_dynamic tr {
			border-bottom: 1px solid var(--survey-js-color-gray-3);
		}
	}

	.sv_matrix_cell {
		vertical-align: top;
	}

	.sv_matrix_cell_detail {
		vertical-align: middle;
		text-align: center;
		width: 32px;
	}

	.sv-table__cell--choice {
		text-align: center;
	}

	.sv_matrix_cell_detail_button {
		width: 29px;
		height: 29px;
		min-width: 0px;
		padding: 0;
		outline: none;
	}

	.sv_matrix_cell_detail_button_expanded {
		background-color: var(--survey-js-color-gray-3);
		&:hover {
			background-color: var(--survey-js-color-gray-2);
		}
	}

	.sv_matrix_cell_detail_rowtext {
		vertical-align: middle;
	}

	.sv_q_m_cell_selected {
		color: var(--survey-js-color-gray-5);
		background-color: var(--survey-js-color-primary-2);
	}

	.sv_q_rating_item {
		&.active .sv_q_rating_item_text {
			background-color: var(--survey-js-color-primary-2);
			border-color: var(--survey-js-color-primary-2);
			color: var(--survey-js-color-gray-5);
		}

		.sv_q_rating_item_text {
			border: 1px solid var(--survey-js-color-gray-3);
			&:hover {
				border: 1px solid var(--survey-js-color-primary-2);
			}
		}
	}

	.sv_q_imgsel.checked label > div {
		background-color: var(--survey-js-color-primary-3);
	}

	.sv_q_file_remove:hover {
		color: var(--survey-js-color-primary-3);
	}

	.sv-boolean__switch {
		background-color: var(--survey-js-color-primary-3);
	}

	.sv-boolean__slider {
		background-color: var(--survey-js-color-gray-5);
	}

	.sv-boolean__label--disabled {
		color: var(--survey-js-color-darken-5);
	}

	.sv_container {
		position: relative;

		.sv_header {
			padding: 1em;

			h3 {
				font-size: 2em;
				font-weight: 300;
				margin: 0;
			}
		}

		.sv_body {
			&.sv_completed_page {
				text-align: center;
				padding: 5em 1em;
			}

			&.sv_completed_page > h3 {
				margin: 0;
				font-size: 30px;
				font-weight: 300;
			}

			.sv-progress {
				height: 0.3em;

				.sv-progress_bar {
					height: 100%;
					min-width: 100px;
					position: relative;
					margin-top: 2em;
					> span {
						position: absolute;
						top: -1.5em;
						display: inline-block;
						white-space: nowrap;
					}
				}
			}

			.sv_p_root {
				.sv_page_title {
					font-size: 1em;
					margin-top: 0.1em;
					font-weight: normal;
				}

				.sv_p_title {
					font-weight: bold;
					font-size: 1.15em;
					margin: 1.5em 0 0 0;
					padding-left: 0;
				}

				.sv_p_panel {
					margin: 0 0 1.5em 0;
				}

				> .sv_row {
					padding: 0;
				}

				.sv_q_title {
					font-weight: bold;
					font-size: 1em;
					margin: 0.5em 0;
				}

				.sv_q_description {
					margin-top: -0.6em;
					min-height: 0.6em;
				}

				.sv_q_flow {
					.sv_q_checkbox_inline,
					.sv_q_radiogroup_inline,
					.sv_q_imagepicker_inline {
						line-height: 2em;
						display: inline-block;
					}
				}

				.sv_q_erbox:not([style*='display: none']):not([style*='display:none']) {
					margin: 1em 0;
					padding: 1em;
				}

				.sv_q {
					padding: 0.5em 1em 1.5em 1em;
					box-sizing: border-box;
					overflow: auto;

					.sv_panel_dynamic {
						.sv_q_title {
							font-weight: normal;
						}

						.sv-paneldynamic__progress-container {
							position: relative;
							display: inline-block;
							width: calc(100% - 250px);
							margin-left: 40px;
							margin-top: 10px;
						}
					}

					.sv_q_other input,
					.sv_q_text_root,
					.sv_q_dropdown_control,
					input:not([type='button']):not([type='reset']):not([type='submit']):not([type='image']):not([type='checkbox']):not([type='radio']),
					select,
					textarea {
						position: relative;
						width: 100%;
						box-sizing: border-box;
						font-size: 1em;
						line-height: 2em;
						padding-left: 1em;

						&:focus {
							outline: none;
						}
					}

					.sv_q_other input,
					.sv_q_dropdown_control,
					input:not([type='button']):not([type='reset']):not([type='submit']):not([type='image']):not([type='checkbox']):not([type='radio']),
					select {
						height: calc(2em + 1px);
					}

					div {
						.sv_q_text_root,
						.sv_q_dropdown_control {
							min-height: 2.43em;
							min-width: 7em;
						}
					}

					.sv_select_wrapper {
						width: 100%;
						position: relative;
						display: inline-block;
						select {
							display: block;
							background: transparent;
							appearance: none;
							-webkit-appearance: none;
							-moz-appearance: none;
							padding-right: 2.5em;
							&::-ms-expand {
								display: none;
							}
						}
						&:before {
							content: '';
							pointer-events: none;
							padding: 1em;

							position: absolute;
							right: 1px;
							top: 1px;
							bottom: 1px;
							z-index: 1;

							background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIxLjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAzNCAzNCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzQgMzQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDojRkZGRkZGO30KPC9zdHlsZT4KPHBvbHlnb24gY2xhc3M9InN0MCIgcG9pbnRzPSIxMiwxNiAxNCwxNCAxNywxNyAyMCwxNCAyMiwxNiAxNywyMSAiLz4KPC9zdmc+Cg==);
							background-repeat: no-repeat;
							background-position: center;
						}
					}

					input {
						&[type='color'] {
							min-height: 2em;
							padding: 0;
						}

						&[type='radio'],
						&[type='checkbox'] {
							margin: 0;
							margin-right: 0.55em;
							width: 1.2em;
							height: 1.2em;
							vertical-align: middle;
							font-size: 1em;
							margin-top: -0.1em;
							&:focus {
								outline: 1px dotted var(--survey-js-color-primary-3);
							}
						}
					}

					.sv_q_radiogroup.sv_q_radiogroup_inline,
					.sv_q_checkbox.sv_q_checkbox_inline,
					.sv_q_imgsel.sv_q_imagepicker_inline {
						&:not(:last-child) {
							margin-right: 1.5em;
						}
					}

					.sv_q_imgsel label > div {
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
						padding: 4px;
						border: 1px solid var(--survey-js-color-gray-3);
						border-radius: 4px;
						> img {
							display: block;
							pointer-events: none;
							position: relative;
						}
					}

					.sv_q_radiogroup_clear {
						margin-top: 0.5em;
					}

					.sv_q_checkbox_inline,
					.sv_q_radiogroup_inline,
					.sv_q_imagepicker_inline {
						line-height: 2em;
						display: inline-block;
					}

					.sv_q_footer {
						padding: 1em 0;
					}
				}

				table {
					width: 100%;
					border-collapse: collapse;

					&.sv_q_matrix .sv_matrix_dynamic_button,
					&.sv_q_matrix_dropdown .sv_matrix_dynamic_button,
					&.sv_q_matrix_dynamic .sv_matrix_dynamic_button {
						padding: 0.3em 2em;
					}

					&.sv_q_matrix {
						td,
						th {
							padding: 0 1em;
							text-align: center;
						}

						td .sv_q_m_label {
							position: static;
							display: block;
							width: 100%;
						}

						td:first-child {
							text-align: left;
						}
					}

					&.sv_q_matrix_dropdown .sv_qcbc .sv_q_checkbox_control_label {
						margin-right: 1.5em;
					}

					td,
					th {
						padding: 0 1em;
					}

					td {
						padding: 0.5em;
					}

					th {
						line-height: 1.3em;
						padding: 0.5em;
						vertical-align: bottom;
						font-weight: bold;
					}
				}

				fieldset.sv_qcbc {
					line-height: 2em;
					padding-top: 0.1em;
				}

				.sv_q_checkbox_label,
				.sv_q_radiogroup_label {
					display: block;
				}

				.sv_q_other {
					margin-left: 1em;
				}

				.sv_q_select_column {
					display: inline-block;
					vertical-align: top;
					min-width: 10%;
				}

				.sv_q_rating {
					line-height: 2em;

					.sv_q_rating_item {
						cursor: pointer;
						word-spacing: -0.3em;
						font-weight: normal;
						display: inline;
						> * {
							word-spacing: initial;
						}

						.sv_q_rating_item_text {
							display: inline-block;
							min-width: 2em;
							padding: 0 0.3em;
							margin-left: -1px;
							text-align: center;
						}

						&:not(:first-child) .sv_q_rating_item_text:not(:hover) {
							border-left-color: transparent;
						}
					}

					.sv_q_rating_min_text {
						margin-right: 0.5em;
					}

					.sv_q_rating_max_text {
						margin-left: 0.5em;
					}
				}
			}

			.sv_nav {
				padding: 1rem 0;
				min-height: 3rem;

				.sv_start_btn,
				.sv_next_btn,
				.sv_complete_btn {
					float: right;
				}

				.sv_preview_btn {
					float: right;
				}

				.sv_prev_btn {
					float: left;
				}

				.sv_edit_btn {
					float: left;
				}
			}
		}
	}

	.sv_q_image {
		display: inline-block;
	}

	[dir='rtl'] input,
	[style*='direction:rtl'] input {
		text-align: right;
	}

	[dir='rtl'] th,
	[style*='direction:rtl'] th {
		text-align: right;
	}

	[dir='rtl'] .sv_container .sv_body .sv_nav .sv_start_btn,
	[dir='rtl'] .sv_container .sv_body .sv_nav .sv_next_btn,
	[dir='rtl'] .sv_container .sv_body .sv_nav .sv_complete_btn,
	[style*='direction:rtl'] .sv_container .sv_body .sv_nav .sv_start_btn,
	[style*='direction:rtl'] .sv_container .sv_body .sv_nav .sv_next_btn,
	[style*='direction:rtl'] .sv_container .sv_body .sv_nav .sv_complete_btn {
		float: left;
	}

	[dir='rtl'] .sv_container .sv_body .sv_nav .sv_prev_btn,
	[style*='direction:rtl'] .sv_container .sv_body .sv_nav .sv_prev_btn {
		float: right;
	}

	.sv_qstn .sv_q_file {
		.sv-visuallyhidden {
			position: absolute !important;
			opacity: 0;
		}

		.sv_q_file_choose_button {
			display: inline-block;
			box-sizing: border-box;
			min-width: 100px;
			line-height: 2em;
			padding: 0.25em 2em;
			font-size: 0.85em;
			font-weight: bold;
			border: none;
			border-radius: 2px;
			cursor: pointer;
		}

		.sv_q_file_remove_button {
			padding: 0.25em 2em;
		}

		.sv-file__decorator {
			display: inline-block;
			min-width: 250px;
			.sv_q_file_placeholder {
				margin-left: 1em;
				display: inline-block;
			}
		}
	}

	.sjs_sp_container {
		position: relative;
	}

	.sjs_sp_controls {
		position: absolute;
		left: 0;
		bottom: 0;
		> button {
			user-select: none;
		}
	}

	.sjs_sp_container > div > canvas:focus {
		outline: none;
	}

	.sv-boolean__decorator {
		border-radius: 2px;
	}

	.sv-boolean__decorator + .sv-boolean__label {
		float: none;
		vertical-align: top;
		margin-left: 0.5em;
	}

	.sv-boolean__svg {
		border: none;
		border-radius: 2px;
		background-color: var(--survey-js-color-primary-3);
		fill: var(--survey-js-color-gray-5);
		width: 24px;
		height: 24px;
	}
	.sv-boolean--allowhover {
		&:hover .sv-boolean__checked-path {
			display: inline-block;
		}

		&:hover .sv-boolean__svg {
			background-color: var(--survey-js-color-grey-2);
			fill: var(--survey-js-color-gray-5);
		}

		&:hover .sv-boolean__unchecked-path,
		&:hover .sv-boolean__indeterminate-path {
			display: none;
		}
	}

	.sv-boolean__checked-path,
	.sv-boolean__indeterminate-path {
		display: none;
	}

	.sv-boolean--indeterminate {
		.sv-boolean__svg {
			background-color: inherit;
			fill: var(--survey-js-color-primary-3);
		}

		.sv-boolean__indeterminate-path {
			display: inline-block;
		}
	}

	.sv-boolean--indeterminate,
	.sv-boolean--checked {
		.sv-boolean__unchecked-path {
			display: none;
		}
	}

	.sv-boolean--checked .sv-boolean__checked-path {
		display: inline-block;
	}

	.sv-boolean--disabled {
		&.sv-boolean--indeterminate .sv-boolean__svg {
			background-color: inherit;
			fill: var(--survey-js-color-gray-3);
		}

		.sv-boolean__svg {
			background-color: var(--survey-js-color-gray-3);
		}
	}

	td.sv_matrix_cell,
	td.td.sv_matrix_cell {
		.sv_qbln {
			text-align: center;
			.sv-boolean {
				text-align: initial;
			}
		}
	}

	@media (min-width: 768px) {
		.sv_container .sv_body .sv_p_root table.sv_q_matrix td {
			min-width: 10em;
		}
	}

	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		.sv_container .sv_body .sv_p_root .sv_q_rating .sv_q_rating_item > * {
			word-spacing: normal;
		}
	}

	@media (max-width: 600px) {
		.sv_container .sv_body .sv_p_root .sv_row {
			.sv_q,
			.sv_qstn {
				display: block;
				width: 100% !important;

				.title-left {
					float: none;
				}

				.sv_q_radiogroup_inline,
				.sv_q_checkbox_inline,
				.sv_q_imagepicker_inline {
					display: block;
				}

				table.sv_q_matrix,
				table.sv_q_matrix_dropdown,
				table.sv_q_matrix_dynamic {
					display: block;
					thead {
						display: none;
					}

					td.sv-table__cell--choice {
						text-align: initial;
					}

					tbody,
					tr,
					td {
						display: block;
					}

					td:before {
						content: attr(headers);
					}
				}

				table.sv_q_matrix {
					td {
						label.sv_q_m_label {
							display: inline;
						}

						&:after {
							content: attr(headers);
						}
					}

					.sv_q_m_cell {
						text-align: initial;
					}
				}
			}
		}
	}
`;

export const SurveyWrapper = ({ children, ...props }: { children: ReactNode }) => (
	<SurveyWrapperWithStyles {...props}>
		<GlobalSurveyCss />
		{children}
	</SurveyWrapperWithStyles>
);
