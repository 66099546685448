import { createGlobalStyle } from 'styled-components';

import 'antd/dist/antd.css';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { ENVIRONMENT } from './constants';

const GlobalStyle = createGlobalStyle`
:root{  
  /* App settings */
  --font-family-default: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  --font-family-mono: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;

  --default-transition-duration: 0.3s;

  --color-background: ${ENVIRONMENT === 'uat' ? '#ffc7ff' : '#f0ffff'};

  --color-primary-1: #103a3c;
  --color-primary-2: #1c7074;
  --color-primary-3: #2b979d;
  --color-primary-4: #37c1c8;
  --color-primary-5: #87dade;
  --color-primary-6: #c3edef;
  --color-primary: var(--color-primary-3);

  --color-secondary-1: #134053;
  --color-secondary-2: #1c607d;
  --color-secondary-3: #2b92be;
  --color-secondary-4: #82c6e3;
  --color-secondary-5: #acd9ec;
  --color-secondary: var(--color-secondary-3);

  --color-gray-1: #262626;
  --color-gray-2: #333333;
  --color-gray-3: #5e5e5e;
  --color-gray-4: #999999;
  --color-gray-5: #ffffff;
  --color-gray: var(--color-gray-3);

  --color-warning-1: #a84a18;
  --color-warning-2: #DF7B44;
  --color-warning-3: #f0a800;
  --color-warning-4: #f0c400;
  --color-warning-5: #fad70f;
  --color-warning: var(--color-warning-3);

  --color-danger-1: #60101a;
  --color-danger-2: #901826;
  --color-danger-3: #f23d53;
  --color-danger-4: #f56979;
  --color-danger-5: #ff9999;
  --color-danger: var(--color-danger-3);


    /* Amplify overwrites */
  --amplify-primary-color: var(--color-primary-3);
  --amplify-primary-tint: var(--color-primary-4);
  --amplify-primary-shade: var(--color-primary-4);

  /* Ant Design overwrites */
  --antd-wave-shadow-color:var(--color-primary-3);

  /* Ag Grid overwrites */
  --ag-border-color: var(--color-gray-5);
  --ag-foreground-color: var(--color-gray-1);
  --ag-background-color: var(--color-gray-5);
  
  --ag-secondary-foreground-color:var(--color-gray-5);

  --ag-selected-row-background-color: var(--color-primary-4);
  --ag-selected-row-foreground-color: var(--color-gray-5);
  --ag-row-hover-color: var(--color-primary-6);
  
  --ag-header-background-color:var(--color-primary-3);
  --ag-header-foreground-color:var(--color-gray-5);
  
  --ag-alpine-active-color:var(--color-primary-6);

  /* Survey JS overwrites */
  --survey-js-color-gray-0:black;
  --survey-js-color-gray-1: var(--color-gray-1);
  --survey-js-color-gray-2: var(--color-gray-2);
  --survey-js-color-gray-3: var(--color-gray-3);
  --survey-js-color-gray-4: var(--color-gray-4);
  --survey-js-color-gray-5: var(--color-gray-5);
  
  --survey-js-color-primary-2: var(--color-primary-2);
  --survey-js-color-primary-3: var(--color-primary-3);
  --survey-js-color-primary-4: var(--color-primary-5);

  --survey-js-color-danger: var(--color-danger);

  --survey-js-color-darken-2: rgba(0,0,0,0.2);
  --survey-js-color-darken-5: rgba(64, 64, 64, 0.5);

  --survey-js-color-row-odd: rgba(0,0,0,0);
  --survey-js-color-row-even: rgba(0,0,0,0);
}

body {
  margin: 0;
  font-family: var(--font-family-default);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: var(---font-family-mono);
}
`;

export default GlobalStyle;
