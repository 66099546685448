import { Descriptions, Menu, Dropdown, Button, Typography, Switch, Space } from 'antd';

// Domain
import { FuzTypeDTO } from '../../../domain/FuzType/FuzType.DTO'


interface PropsInterface {
    FuzType: FuzTypeDTO;
    handleClickUpdateAttribute: any;
    handleSwitchEnabled:any;
}

export default function FuzTypeTableExpand(props: PropsInterface) {
    const FuzType: FuzTypeDTO = props.FuzType;

    function handleMenuClick(e: any) {
        console.log('Open update modal', e.key, 'for: ', FuzType.TypeName);
        props.handleClickUpdateAttribute(FuzType.TypeId, e.key);
    }

    function handleSwitchChange(e:Boolean) {
        props.handleSwitchEnabled(FuzType.TypeId,e)
    }

    const updateMenu = (
        <Menu onClick={handleMenuClick}>
            <Menu.Item key="TypeName">Fuz Name</Menu.Item>
            <Menu.Item key="UploadDescription">Upload Description</Menu.Item>
        </Menu>
    );

    return (
        <div>
            <Descriptions title={FuzType.TypeName} column={1}
                extra={
                    <div>
                        <Space>
                            <Switch checkedChildren="Enabled" unCheckedChildren="Disabled" checked={FuzType.Enabled} onChange={handleSwitchChange}/>
                            <Dropdown overlay={updateMenu} placement="bottomCenter" arrow>
                                <Button>Update</Button>
                            </Dropdown>
                        </Space>
                    </div>
                }
            >
                <Descriptions.Item label="Id"><Typography.Paragraph copyable>{FuzType.TypeId}</Typography.Paragraph></Descriptions.Item>
                <Descriptions.Item label="Upload Description">{FuzType.UploadDescription}</Descriptions.Item>
                <Descriptions.Item label="Max File Size">{FuzType.MaxFileUploadSizeMb} Megabyte</Descriptions.Item>
                <Descriptions.Item label="Allowed File Types">{FuzType.AllowedFileTypes}</Descriptions.Item>
                <Descriptions.Item label="File Name Regex">{FuzType.FileNameRegex}</Descriptions.Item>
            </Descriptions>
        </div>
    )
}