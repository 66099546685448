import { Collapse, Typography } from 'antd';

// Components
import UploadZone from './UploadZone'
import { FuzTypeDTO } from '../../domain/FuzType/FuzType.DTO'
import { UserDTO } from '../../domain/User/User.DTO'

// Services
import FuzUploadService from '../../domain/FuzType/FuzUpload.Service'
import { FuzUploadDTO } from '../../domain/FuzType/FuzUpload.DTO';

const fuzUploadService = new FuzUploadService();
const { Panel } = Collapse;

interface PropInterface {
    userInfo: UserDTO;
    fuzTypes: FuzTypeDTO[];
    userUploads: FuzUploadDTO[];
}

export default function UploadContainer(props: PropInterface) {

    async function uploadFile(fuzType: FuzTypeDTO, File: any) {
        console.log("Uploading: ", fuzType.TypeName, "=> ", File.file.name)
        await fuzUploadService.uploadFile(fuzType, props.userInfo, File)
    }


    return (
        <div style={{ padding: '2em' }}>
            <Typography.Title level={1}>Upload Zone</Typography.Title>
            <Collapse accordion >
                {
                    props.fuzTypes.map((FuzType: FuzTypeDTO, i: number) => (
                        <Panel header={`${FuzType.TypeName}`} key={FuzType.TypeId}>
                            <UploadZone
                                fuzType={FuzType}
                                uploadFile={(fuzType: FuzTypeDTO, file: any) => uploadFile(fuzType, file)}
                                userUploads={props.userUploads ? props.userUploads.filter(f => f.FuzTypeId === FuzType.TypeId) : []}
                            />
                        </Panel>
                    ))
                }
            </Collapse>
        </div>
    )
}


