import { Modal, Button, Descriptions, Typography } from 'antd';

import { FuzEventDTO } from '../../../domain/FuzType/FuzEvent.DTO'
import { FuzUploadDTO } from '../../../domain/FuzType/FuzUpload.DTO'

import { formatBytes } from '../../../utils/FileSizeHelper'

interface Propsinterface {
    fuzEvent?: FuzEventDTO;
    fuzUploadInfo?: FuzUploadDTO;
    modalVisible: boolean;
    handleCancel: any;
}

export default function FuzEventModal(props: Propsinterface) {
    return (
        <Modal
            title="Event Info"
            visible={props.modalVisible}
            onCancel={() => props.handleCancel()}
            footer={[
                <Button key="Cancel" onClick={() => props.handleCancel()}>
                    Close
                </Button>
            ]}
            width={1200}
        >
            <Descriptions title="Event Info" bordered column={1}>
                <Descriptions.Item label="Datetime">{props.fuzEvent?.CreatedAt}</Descriptions.Item>
                <Descriptions.Item label="Status">{props.fuzEvent?.Status}</Descriptions.Item>
                <Descriptions.Item label="User Id"><Typography.Paragraph copyable>{props.fuzEvent?.UserId}</Typography.Paragraph></Descriptions.Item>
                {props.fuzEvent?.Comment
                    ? <Descriptions.Item label="Error">{props.fuzEvent?.Comment}</Descriptions.Item>
                    : ""}
            </Descriptions>
            {props.fuzUploadInfo ?
                <Descriptions title="Upload Info" bordered column={1}>
                    <Descriptions.Item label="Upload Id"><Typography.Paragraph copyable>{props.fuzUploadInfo.UploadId}</Typography.Paragraph></Descriptions.Item>
                    <Descriptions.Item label="File Name">{props.fuzUploadInfo.FileName}</Descriptions.Item>
                    <Descriptions.Item label="File Type">{props.fuzUploadInfo.FileType}</Descriptions.Item>
                    <Descriptions.Item label="File Size">{formatBytes(props.fuzUploadInfo.FileSize, 2)}</Descriptions.Item>
                    <Descriptions.Item label="S3 Key">{props.fuzUploadInfo.S3Key}</Descriptions.Item>
                </Descriptions>
                : ""}
        </Modal>
    )
}