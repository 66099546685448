import { FuzDownloadDTO } from "./FuzDownload.DTO";
import FuzDownloadApi from './FuzDownload.Api'

const fuzDownloadApi = new FuzDownloadApi();

export default class FuzDownloadService{
    // Get Fuz Download
    async getFuzDownload(userId:string,uploadId:string){
        const data:FuzDownloadDTO = await fuzDownloadApi.getFuzDownload(userId,uploadId);
        return data
    }
}