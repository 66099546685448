import {FuzTypeDTO,FuzTypeCreateDTO} from './FuzType.DTO'
import FuzTypeApi from './FuzType.Api'

const fuzTypeApi = new FuzTypeApi();

export default class FuzTypeService{
    // Get All Fuz Types
    async getFuzTypes(){
        const data:FuzTypeDTO[] = await fuzTypeApi.getAllFuzTypes()
        return data
    }

    // Get Fuz Type by id
    async getFuzTypeById(FuzTypeId:string){
        const data:FuzTypeDTO = await fuzTypeApi.getFuzTypeById(FuzTypeId)
        return data
    }

    // Create Fuz Type
    async createFuzType(FuzType:FuzTypeCreateDTO){
        console.log("service creating fuz type:, ",FuzType)
        const FuzTypeToCreate:FuzTypeCreateDTO={
            TypeName:FuzType.TypeName,
            AllowedFileTypes:FuzType.AllowedFileTypes,
            MaxFileUploadSizeMb: FuzType.MaxFileUploadSizeMb,
            UploadDescription: FuzType.UploadDescription,
            Enabled: FuzType.Enabled,
            ValidationSchemas: FuzType.ValidationSchemas,
            FileNameRegex: FuzType.FileNameRegex
        }
        console.log("service creating fuz type2:, ",FuzTypeToCreate)

        return await fuzTypeApi.createFuzType(FuzTypeToCreate)
    }

    // Delete Fuy Type
    async deleteFuzType(FuzTypeId:string){
        return await fuzTypeApi.deleteFuzType(FuzTypeId)
    }

    // Update Fuz Type Attributes
    async updateAttribute(FuzTypeId:string,AttributeName:string,AttributeValue:string){
        return await fuzTypeApi.patchUpdateAttribute(FuzTypeId,AttributeName,AttributeValue)
    }

    // Update Full Fuz Type
    async updateFullFuzType(FuzTypeId:string,body:any){
        console.log("updateFullFuzType:",body)
        return await fuzTypeApi.updateFullFuzType(FuzTypeId,body)
    }

    // Get Fuz Events For Fuz Type
    async getEventsByFuzId(FuzTypeId:string){
        return await fuzTypeApi.getFuzEventsForFuzType(FuzTypeId)
    }

    // Get Distinct Users From Upload Events for specific Fuz Type
    async getDistinctUsersForFuzType(fuzTypeId:string){
        return await fuzTypeApi.getDistinctUsersForFuzType(fuzTypeId)
    }
}