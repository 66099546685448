import HttpClient from "../../api/httpClient";
import { FuzTypeDTO } from "../FuzType/FuzType.DTO";

const httpClient = new HttpClient();
const BASE_URL = process.env.REACT_APP_FUZ_API_URL

export default class FuzAllocationApi{
    // Get Allocations For Fuz Type
    async getAllocationsForFuzType(FuzTypeId:string){
        const url:string = BASE_URL + `/fuzallocation/fuztype/${FuzTypeId}`
        const data:string[] = await httpClient.performGetRequest(url)
        return data
    }

    // Update Allocations For Fuz Type
    async updateAllocationsForFuzType(FuzTypeId:string, Allocations:string[]){ 
        const url:string = BASE_URL + `/fuzallocation/fuztype/${FuzTypeId}`
        const response:any = await httpClient.performPatchRequestPayload(url,Allocations)
        return response
    }

    // Get FuzTypes From TeamKeys
    async getFuzTypesFromTeamKeys(TeamKeys:string[]){
        const url:string = BASE_URL + `/fuzallocation/teamkey`
        const response:FuzTypeDTO[] = await httpClient.performPostRequest(url,TeamKeys)
        return response
    }
}