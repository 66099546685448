import { useState } from 'react'
import { Table, Tag, Space } from 'antd';

import ButtonInfo from '../../Shared/Button.Info';
import FuzEventModal from './FuzEvent.Modal'

import { FuzEventDTO, FuzEventStatus } from '../../../domain/FuzType/FuzEvent.DTO'
import FuzUploadService from '../../../domain/FuzType/FuzUpload.Service'
import { FuzUploadDTO } from '../../../domain/FuzType/FuzUpload.DTO'

const fuzUploadService = new FuzUploadService();

interface Propsinterface {
    events: FuzEventDTO[];
}

export default function FuzEventTable(props: Propsinterface) {
    const [modalEventInfoVisible, setModalEventInfoVisible] = useState<boolean>(false);
    const [modalEvent, setModalEvent] = useState<FuzEventDTO>();
    const [modalUploadInfo, setModalUploadInfo] = useState<FuzUploadDTO>();

    async function handleClickInfo(record: FuzEventDTO) {
        console.log("handleClickInfo: ", record);
        setModalEvent(record)
        setModalEventInfoVisible(true)
        if (record.Status === FuzEventStatus.SUCCESS && record.UploadId) {
            const data: FuzUploadDTO = await fuzUploadService.getUpload(record.UserId, record.UploadId)
            setModalUploadInfo(data)
            console.log("Get Upload Info:", data)

        }
    }

    async function closeModalEvent() {
        setModalEventInfoVisible(false);
        setModalEvent(undefined)
        setModalUploadInfo(undefined)
    }

    const sorter = (a:any, b:any) => (isNaN(a) && isNaN(b) ? (a || '').localeCompare(b || '') : a - b);
    const columns = [
        {
            title: 'Datetime',
            dataIndex: 'CreatedAt',
            key: 'CreatedAt',
            render: (text: string) => (new Date(text).toLocaleString()),
            sorter: (a:any, b:any) => sorter(a.CreatedAt, b.CreatedAt),
            defaultSortOrder: 'ascend' as 'ascend'
        },
        {
            title: 'Status',
            dataIndex: 'Status',
            key: 'Status',
            render: (Status: string) => (
                <div>
                    {(Status === FuzEventStatus.SUCCESS)
                        ? <Tag color="success">{Status}</Tag>
                        : <Tag color="error">{Status}</Tag>
                    }
                </div>
            )
        },
        {
            title: 'Info',
            key: 'action',
            render: (text: string, record: FuzEventDTO) => (
                <Space size="middle">
                    <ButtonInfo text="Info" disabled={false} onClick={() => handleClickInfo(record)} />
                </Space>
            ),
        },

    ];

    return (
        <div>
            <Table columns={columns} dataSource={props.events} sortDirections={["ascend", "descend"]}/>
            <FuzEventModal
                fuzEvent={modalEvent}
                fuzUploadInfo={modalUploadInfo}
                modalVisible={modalEventInfoVisible}
                handleCancel={() => closeModalEvent()}
            />
        </div>
    )
}