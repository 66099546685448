import { TeamCreateDTO } from './Team.DTO'

import TeamApi from './Team.Api'
const teamApi = new TeamApi();

export default class TeamService {
    async getTeams() {
        const teams = await teamApi.getAllTeams()
        return teams
    }

    // async getTeamTree() {
    //     const teams:TeamDTO[] = await teamApi.getAllTeams()
    //     const teamTree:any = generateTeamTree(teams)
    //     return teamTree
    // }
    async getTeamTree() {
        const teamTree: any = await teamApi.getTeamTree()
        return teamTree
    }

    async createTeam(data: TeamCreateDTO) {
        return await teamApi.createTeam(data)
    }

    async deleteTeam(teamKey: string) {
        return await teamApi.deleteTeam(teamKey)
    }
}