import {TeamDTO, TeamCreateDTO} from './Team.DTO'

import HttpClient from "../../api/httpClient";
const httpClient = new HttpClient();

const BASE_URL = process.env.REACT_APP_USER_MANAGEMENT_URL

export default class TeamApi{
    async getAllTeams(){
        const url:string = BASE_URL + `/team/teams`;
        const data:TeamDTO[] = await httpClient.performGetRequest(url)
        return data
    }

    async createTeam(data:TeamCreateDTO){
        const url:string = BASE_URL + `/team/teams`;
        const response:any = await httpClient.performPostRequest(url,data)
        return response
    }

    async getTeam(teamKey:string){
        const url:string = BASE_URL + `/team/teams/${teamKey}`;
        const response:any = await httpClient.performGetRequest(url)
        return response
    }

    async deleteTeam(teamKey:string){
        const url:string = BASE_URL + `/team/teams/${teamKey}`;
        const response:any = await httpClient.performDeleteRequest(url)
        return response
    }

    async getTeamTree(){
        const url:string = BASE_URL + `/team/teams/tree`;
        const data:TeamDTO[] = await httpClient.performGetRequest(url)
        return data
    }
}