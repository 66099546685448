export const FuzTypeCreateSurvey = {
  pages: [
    {
      name: "Info",
      elements: [
        {
          type: "text",
          name: "TypeName",
          title: "Fuz Type Name",
          isRequired: true,
        },
        {
          type: "comment",
          name: "UploadDescription",
          title: "Upload Description",
          isRequired: true,
        },
        {
          type: "checkbox",
          name: "AllowedFileTypes",
          title: "Allowed File Types",
          isRequired: true,
          choices: [
            {
              value: ".csv",
              text: "CSV",
            },
            {
              value: ".json",
              text: "JSON",
            },
            {
              value: ".xml",
              text: "XML",
            },
            {
              value: ".xlsx",
              text: "Excel",
            },
            {
              value: ".zip",
              text: "Zip",
            },
          ],
        },
        {
          type: "text",
          name: "MaxFileUploadSizeMb",
          title: "Max Upload Size (Mb)",
          isRequired: true,
          inputType: "number",
          min: "0",
          max: "10",
        },
          {
              type: "text",
              name: "FileNameRegex",
              title: "Validation File Name Regex",
              isRequired: false
          }
      ],
      description: "Fill in information about the Fuz Type",
    },
    {
      name: "Validation Rules",
      elements: [
        {
          type: "paneldynamic",
          name: "ValidationSchemas",
          title: "Validation Schemas",
          description: "Add validation schemas",
          templateElements: [
            {
              type: "dropdown",
              name: "ValidationSchemaType",
              title: "Validation Schema Type",
              isRequired: true,
              choices: [
                {
                  value: "csv",
                  text: "csv",
                },
                {
                  value: "excel",
                  text: "excel",
                },
                {
                  value: "json",
                  text: "json",
                },
              ],
            },
            {
              type: "boolean",
              name: "AllowAdditionalColumns",
              visibleIf:
                "{panel.ValidationSchemaType} = 'csv' or {panel.ValidationSchemaType} = 'excel'",
              title: "Allow Additional Columns",
            },
            {
              type: "text",
              name: "SheetName",
              visibleIf: "{panel.ValidationSchemaType} = 'excel'",
              title: "Sheet Name",
            },
            {
              type: "paneldynamic",
              name: "ColumnDefinitions",
              visibleIf:
                "{panel.ValidationSchemaType} = 'csv' or {panel.ValidationSchemaType} = 'excel'",
              title: "Column Definitions",
              bindings: {
                panelCount: "ValidationSchemas",
              },
              templateElements: [
                {
                  type: "text",
                  name: "name",
                  title: "Column Name",
                  isRequired: true,
                  requiredErrorText: "Column name is not valid.",
                  validators: [
                    {
                      type: "expression",
                      text: "Column Name must be without spaces",
                      expression:
                        "{ValidationSchemas[0].ColumnDefinitions[0].name} notcontains ' '",
                    },
                  ],
                },
                {
                  type: "dropdown",
                  name: "type",
                  title: "Column Type",
                  isRequired: true,
                  choices: [
                    {
                      value: "string",
                      text: "string",
                    },
                    {
                      value: "numeric",
                      text: "Numeric",
                    },
                    {
                      value: "boolean",
                      text: "Boolean",
                    },
                    {
                      value: "datetime",
                      text: "datetime",
                    },
                  ],
                },
                {
                  type: "boolean",
                  name: "required",
                  title: "Column Required",
                  isRequired: true,
                },
                {
                  type: "boolean",
                  name: "null_or_empty",
                  title: "Column can be Null Or Empty?",
                  isRequired: true,
                },
              ],
              panelAddText: "Add Column Definition",
              panelRemoveText: "Remove Column Definition",
            },
            {
              type: "comment",
              name: "jsonschema",
              visibleIf: "{panel.ValidationSchemaType} = 'json'",
              title: "JSON Schema",
              description: "Paste in JSON Schema",
            },
          ],
          panelAddText: "Add Validation Schema",
          panelRemoveText: "Remove Validation Schema",
        },
      ],
      title: "Validation Rules",
    },
  ],
  showCompletedPage: false,
  showProgressBar: "top",
  progressBarType: "buttons",
};
