import { Translations } from '@aws-amplify/ui-components';

export const AMPLIFY_LOCALE_BE_NL = {
	[Translations.SIGN_IN_HEADER_TEXT]: 'Log in op uw account',
	[Translations.USERNAME_LABEL]: 'Gebruikersnaam *',
	[Translations.USERNAME_PLACEHOLDER]: 'Voer uw gebruikersnaam in',
	[Translations.PASSWORD_LABEL]: 'Wachtwoord *',
	[Translations.PASSWORD_PLACEHOLDER]: 'Voer uw wachtwoord in',
	[Translations.FORGOT_PASSWORD_TEXT]: 'Wachtwoord vergeten?',
	[Translations.RESET_PASSWORD_TEXT]: 'Reset je wachtwoord',
	[Translations.SIGN_IN_ACTION]: 'Inloggen',
	[Translations.NO_ACCOUNT_TEXT]: 'Geen account?',
	[Translations.RESET_YOUR_PASSWORD]: ' ',
	[Translations.BACK_TO_SIGN_IN]: 'Terug naar inloggen',
	[Translations.SEND_CODE]: 'Code versturen',
	[Translations.SIGN_OUT]: 'Uitloggen',
	[Translations.CREATE_ACCOUNT_TEXT]: 'Account aanmaken',
	[Translations.CHANGE_PASSWORD]: 'Wachtwoord Aanpassen',
	[Translations.NEW_PASSWORD_LABEL]: 'Wachtwoord moet minstens 8 karakters hebben en voorzien zijn van een hoofdletter en een nummer.',
	[Translations.NEW_PASSWORD_PLACEHOLDER]: 'Voer een nieuw wachtwoord in.',
	[Translations.CHANGE_PASSWORD_ACTION]: 'Pas Aan',
	[Translations.VERIFY_CONTACT_HEADER_TEXT]: 'Om in de toekomst het wachtwoord aan te kunnen passen moet de email worden geverifieerd.',
	[Translations.VERIFY_CONTACT_VERIFY_LABEL]: 'Verifieer',
	[Translations.SKIP]: 'Sla Over',
};
