import { useState, useEffect } from "react";
import { useParams } from 'react-router'

// components
import FuzTypeInfo from '../components/Admin/FuzTypeInfo/FuzTypeInfo'

// Services
import FuzTypeService from '../domain/FuzType/FuzType.Service'
import { FuzTypeDTO} from "../domain/FuzType/FuzType.DTO";
import {FuzEventDistinctUsersDTO} from '../domain/FuzType/FuzEvent.DTO'
import TeamService from '../domain/Team/Team.Service'
import { TeamDTO } from '../domain/Team/Team.DTO'

const teamService = new TeamService();
const fuzTypeService = new FuzTypeService();

interface RouteParams {
    id: string;
}

export default function FuzTypeRoute() {
    const params = useParams<RouteParams>();
    const FuzTypeId: string = params.id;

    const [fuzType, setFuzType] = useState<FuzTypeDTO>()
    const [fuzEvents,setFuzEvents] = useState([])
    const [fuzEventsDistinctUsers,setFuzEventsDistinctUsers] = useState<FuzEventDistinctUsersDTO[]>()
    const [Teams, setTeams] = useState<TeamDTO[]>([]);

    useEffect(() => {
        const getData = async () => {
            const data: FuzTypeDTO = await fuzTypeService.getFuzTypeById(FuzTypeId)
            console.log("Fuz Type info: ",data)
            setFuzType(data)

            const events = await fuzTypeService.getEventsByFuzId(FuzTypeId)
            console.log("Fuz Events: ",events)
            setFuzEvents(events)

            const distinctUsers = await fuzTypeService.getDistinctUsersForFuzType(FuzTypeId)
            setFuzEventsDistinctUsers(distinctUsers)

            const teams: TeamDTO[] = await teamService.getTeamTree()
            console.log("Teams: ", teams)
            setTeams(teams)
           }
        getData()
    }, []);


    return(
        <div  style={divStyle}>
            {fuzType ?
                        <FuzTypeInfo FuzType={fuzType} fuzEvents={fuzEvents} fuzEventsDistinctUsers={fuzEventsDistinctUsers}/>
                :""
            }   
        </div>
    )
}

const divStyle = {
    padding: "3em"
}
