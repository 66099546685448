import toast from 'react-hot-toast';

export default class NotificationsService{

    success(text:string){
        const toastId = toast.success(text)
        return toastId;
    }

    error(text:string){
        const toastId = toast.error(text);
        return toastId;
    }

    loading(text:string){
        const toastId =  toast.loading(text);
        return toastId;
    }

    dismiss( toastId:any){
        toast.dismiss(toastId);
    }

    dismissAll(){
        toast.dismiss();
    }

    updateLoadingSuccess(toastId:any,text:string){
        toast.success(text, {
            id: toastId,
          });
    }

    updateLoadingError(toastId:any,text:string){
        toast.error(text, {
            id: toastId,
          });
    }
}