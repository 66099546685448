import styled from 'styled-components';

const FullPageCenterContainer = styled.div`
	background-color: var(--color-background, white);

	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;
export default FullPageCenterContainer;
