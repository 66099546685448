import HttpClient from "../../api/httpClient";
import { FuzDownloadDTO } from "./FuzDownload.DTO";

const httpClient = new HttpClient();
const BASE_URL = process.env.REACT_APP_FUZ_API_URL

export default class FuzDownloadApi{
    // Get Fuz Download
    async getFuzDownload(userId:string,uploadId:string){
        const url:string = BASE_URL + `/fuzdownload/${userId}/${uploadId}`
        const data:FuzDownloadDTO = await httpClient.performGetRequest(url)
        return data
    }
}